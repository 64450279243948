import { SelectOption } from '.';

export enum Countries {
  UnitedStates = 'US', // +1
  Canada = 'CA', // +1
  Belgium = 'BE', // +32
  France = 'FR', // +33
  Spain = 'ES', // +34
  Luxembourg = 'LU', // +352
  Italy = 'IT', // +39
  Switzerland = 'CH', // +41
  GreatBritain = 'GB', // +44
  Germany = 'DE', // +49
}
/* Country, Brevo price for 1 000 SMS, country calling code
  USA : 10,00 € // +1
  Canada : 10,00 € // +1
  Pologne : 16,90 € // +48
  Royaume-Uni : 31,70 € // +44
  Espagne : 43,40 € // +34
  Italie : 43,40 € // +39
  France : 45,00 € // +33
  Suisse : 49,00 € // +41
  Andorra : 51,00 € // +376
  Irlande : 53,00 € // +353
  Luxembourg : 55,00 € // +352
  Portugal : 61,60 € // +351
  Allemagne : 90,00 € // +44
  Monaco : 91,00 € // +377
  Belgique : 95,00 € // +32
  Pays-Bas : 95,00 € // +31
  Martinique : 96,00 € // +596
*/
export const countriesWithPhone = Object.values(Countries);

export const CountryPhoneData: SelectOption[] =
  countriesWithPhone.map((countryCode) => ({
    value: countryCode,
    viewValue: countryCode,
  }) as SelectOption);

export class PhoneModel {
  digits: string;
  extension: string;
  e164PhoneNumber: string;

  constructor(phoneNumber?: string | PhoneModel) {
    if (typeof phoneNumber === 'string') {
      this.e164PhoneNumber = phoneNumber;
    } else if (phoneNumber && phoneNumber.digits && phoneNumber.extension) {
      this.digits = phoneNumber.digits;
      this.extension = phoneNumber.extension;
      this.e164PhoneNumber = phoneNumber.e164PhoneNumber;
    }
  }

}
