<div id="event" class="events">
  <div class="events__carousel">
    <app-loader *ngIf="isLoadingEvents" diameter="40" class="m-auto"></app-loader>

    <app-carousel *ngIf="!isLoadingEvents" [items]="events">
      <ng-template let-item="item">
        <app-event-card [event]="item" class="events__carousel__item"></app-event-card>
      </ng-template>
    </app-carousel>
  </div>

</div>
