<div class="form" [formGroup]="formGroup">
  <ng-container *ngFor="let field of customBuyerFields">
    <mat-form-field *ngIf="field.type === 'string'" class="form__field mb-2" >
      <input [appTrim]="true" [formControlName]="field.name" matInput [placeholder]="field.name" [required]="field.isRequired">
      <mat-error *ngIf="getError(field.name)">{{ 'formErrors.' + getError(field.name) | transloco }}</mat-error>
    </mat-form-field>
    
    <mat-form-field *ngIf="field.type === 'number'" class="form__field mb-2">
      <input [formControlName]="field.name" type="number" (onWheel)="$event?.preventDefault()" matInput [placeholder]="field.name" [required]="field.isRequired">
      <mat-error *ngIf="getError(field.name)">{{ 'formErrors.' + getError(field.name) | transloco }}</mat-error>
    </mat-form-field>
    
    <ng-container *ngIf="field.type === 'boolean'">
      <mat-checkbox *ngIf="field.type === 'boolean'" class="form__field mb-2" [name]="field.name" [formControlName]="field.name" [required]="field.isRequired">
        <p class="checkbox-text">{{field.name}}</p>
      </mat-checkbox>
      <mat-error *ngIf="getError(field.name)">{{ 'formErrors.' + getError(field.name) | transloco }}</mat-error>
    </ng-container>

    <ng-container *ngIf="field.type === 'date'">

      <p class="mb-3">{{ field.name }}</p>

      <div [formGroupName]="field.name" class="form --one-line-xs full-width" *transloco="let tu; read 'timeUnit'">

        <mat-form-field class="form__field --day">
          <mat-label>{{ tu('long.day') }}</mat-label>
          <mat-select matNativeControl formControlName="day" (selectionChange)="onDateChange(field.name)" required>
            <mat-option *ngFor="let day of availableDays" [value]="day">{{day}}</mat-option>
          </mat-select>
          <mat-error *ngIf="getError(field.name + 'day')">{{ 'formErrors.' + getError(field.name + 'day') | transloco }}</mat-error>
        </mat-form-field>

        <mat-form-field class="form__field" *transloco="let tcm; read 'calendar.months'">
          <mat-label>{{ tu('long.month') }}</mat-label>
          <mat-select matNativeControl formControlName="month" (selectionChange)="onDateChange(field.name)" required>
            <mat-option *ngFor="let month of availableMonths; let i=index" [value]="i+1">{{tcm(month)}}</mat-option>
          </mat-select>
          <mat-error *ngIf="getError(field.name + 'month')">{{ 'formErrors.' + getError(field.name + 'month') | transloco }}</mat-error>
        </mat-form-field>

        <mat-form-field class="form__field">
          <mat-label>{{ tu('long.year') }}</mat-label>
          <mat-select matNativeControl formControlName="year" (selectionChange)="onDateChange(field.name)" required>
            <mat-option *ngFor="let year of availableYears" [value]="year">{{year}}</mat-option>
          </mat-select>
          <mat-error *ngIf="getError(field.name + 'year')">{{ 'formErrors.' + getError(field.name + 'year') | transloco }}</mat-error>
        </mat-form-field>

      </div>

      <mat-error *ngIf="getError(field.name)">{{ 'formErrors.' + getError(field.name) | transloco }}</mat-error>

    </ng-container>
  </ng-container>
  
</div>
