<ng-container *appLet="(appService.settings.asObservable() | async) as setting">
  <div class="page-container" *transloco="let tnf; read 'notFound'">

    <app-loader *ngIf="isLoading" diameter="40" class="my-5 mx-auto"></app-loader>
  
    <div class="not-found" *ngIf="!isLoading">
      <p class="title --page mb-5">{{ tnf('woops') }}</p>
  
      <div class="not-found__img-div">
        <img class="not-found__img-div__img" src="assets/img/illustration/not-found.svg" alt="not-found">
      </div>
  
      <p class="not-found__text">{{ tnf('thisPageDoestNotExist') }}</p>
  
      <div *ngIf="!setting?.isWidget" class="buttons-group --row --centered">
        <a routerLink="/evenements">
          <button mat-button class="button --outlined-primary">
            {{ 'button.seeAvailableEvents' | transloco }}
          </button>
        </a>
        <a routerLink="/">
          <button mat-button class="button --primary">
            {{ 'button.goToTheHomePage' | transloco }}
          </button>
        </a>
      </div>
    </div>
  </div>  
</ng-container>
