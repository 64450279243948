// eslint-disable-next-line max-classes-per-file
import { UserProfile } from './user-profile.model';
import { priceFormatter, seatFormatter } from './formatter';
import { Category, Event, TicketRecall, TicketingPlatform } from '.';
import { Currencies, getCurrencySymbol } from './currencies.model';
import { SeatingInformation } from './seating-information.model';

export enum TicketStatus {
  ApiError = 'apiError',
  Sold = 'sold',
  Resold = 'resold',
  PendingResold= 'pendingResold',
  PendingSellerConfirmation = 'pendingSellerConfirmation',
  PendingPurchase = 'pendingPurchase',
  Sale = 'sale',
  Withdrawn = 'withdrawn',
  NotValid = 'notValid',
  PendingPayment = 'pendingPayment',
  PendingTicketDelivery = 'pendingTicketDelivery',
  PendingValidation = 'pendingValidation',
  PrivateSale = 'privateSale',
  FriendSale = 'friendSale',
  PendingSwap = 'pendingSwap',
}

export enum Actions {
  apiError = 'Mettre en erreur api',
  sale= 'Remettre en vente',
  notValid= 'Invalider',
  friendSale= 'Mettre en vente à un(e) ami(e)',
  pendingPayment= 'Mettre en paiement en cours',
  pendingPurchase= 'Mettre en achat en cours',
  pendingSellerConfirmation= 'Mettre en attente de confirmation du vendeur',
  pendingTicketDelivery= 'Mettre en attente de livraison',
  pendingValidation= 'Mettre en attente de validation',
  withdrawn= 'Annuler',
  sold= 'Vendre',
}

export const ticketStatusInfos = {
  [TicketStatus.ApiError]: {
    key : 'apiError',
    name : 'erreur api',
    color: '#ce4358',
  },
  [TicketStatus.Sold]: {
    key : 'sold',
    name : 'vendu',
    color: '#3bceac',
  },
  [TicketStatus.PendingSellerConfirmation]: {
    key : 'pendingSellerConfirmation',
    name : 'en attente de confirmation du vendeur',
    color: '#ce4358',
  },
  [TicketStatus.PendingPurchase]: {
    key : 'pendingPurchase',
    name : 'en cours d\'achat',
    color: '#ce7d43',
  },
  [TicketStatus.Sale]: {
    key : 'sale',
    name : 'en vente',
    color: '#ce7d43',
  },
  [TicketStatus.Withdrawn]: {
    key : 'withdrawn',
    name : 'annulé',
    color: '#ce4358',
  },
  [TicketStatus.NotValid]: {
    key : 'notValid',
    name : 'non valide',
    color: '#ce4358',
  },
  [TicketStatus.PendingPayment]: {
    key : 'pendingPayment',
    name : 'paiement en cours',
    color: '#ce7d43',
  },
  [TicketStatus.PendingTicketDelivery]: {
    key : 'pendingTicketDelivery',
    name : 'en attente de livraison',
    color: '#ce4358',
  },
  [TicketStatus.PendingValidation]: {
    key : 'pendingValidation',
    name : 'en attente de validation',
    color: '#ce4358',
  },
  [TicketStatus.PrivateSale]: {
    key : 'privateSale',
    name : 'en vente privée',
    color: '#ce7d43',
  },
  [TicketStatus.FriendSale]: {
    key : 'friendSale',
    name : 'en vente à un ami',
    color: '#ce7d43',
  },
  [TicketStatus.PendingSwap]: {
    key : 'pendingSwap',
    name : 'en cours de swap',
    color: '#ce4358',
  },
};

export enum InvoiceType {
  Ticket = 'T',
  AutoPurchase = 'A',
}

export class TicketSaleData {
  eventId: number;
  originalLastName: string;
  originalFirstName: string;
  originalTicketNumber: string;
  price: number;
  categoryId: string;
  payoutAuto: boolean;
  sellerCommercialCommunication: boolean;
  friendSale: boolean;
  seatDetails: SeatDetails;
  upgraded: boolean;

  constructor(options?: any) {
    if (options) {
      this.eventId = options.eventId;
      this.originalFirstName = options.originalFirstName;
      this.originalLastName = options.originalLastName;
      this.originalTicketNumber = options.originalTicketNumber;
      this.price = options.price;
      this.categoryId = options.categoryId;
      this.payoutAuto = options.payoutAuto;
      this.sellerCommercialCommunication = options.sellerCommercialCommunication;
      this.friendSale = options.friendSale;
      this.seatDetails = new SeatDetails(options.seatDetails);
      this.upgraded = options.upgraded;
    }
  }
}

export class Invoice {
  id: number;
  token: string;
  invoiceFileName: string;
  date: Date;
  // eslint-disable-next-line id-denylist
  number: string;
  invoiceAmount: number;
  tvaRate: number;
  type: InvoiceType;

  constructor(options?: any) {
    if (options) {
      this.id = options.id;
      this.token = options.token;
      this.invoiceFileName = options.invoiceFileName;
      this.date = options.date;
      // eslint-disable-next-line id-denylist
      this.number = options.number;
      this.tvaRate = options.tvaRate;
    }
  }
}

export class TicketHolder {
  ticketId: number;
  originalTicketNumber: string;
  originalFirstName: string;
  originalLastName: string;
  newFirstName: string;
  newLastName: string;
  newTicketNumber: string;

  constructor(options?: any) {
    if (options) {
      this.ticketId = options.ticketId;
      this.originalTicketNumber = options.originalTicketNumber;
      this.originalFirstName = options.originalFirstName;
      this.originalLastName = options.originalLastName;
      this.newFirstName = options.newFirstName;
      this.newLastName = options.newLastName;
      this.newTicketNumber = options.newTicketNumber;
    }
  }
}

export enum PayoutStatus {
  Done = 'done',
  Pending = 'pending',
  Hold = 'hold',
}

export enum PurchaseFlow { // database type is STRING(6)
  AutomaticPurchase = 'auto',
  FriendSale = 'friend',
  PublicSale = 'public',
  WLMail = 'wlmail',
}

export const purchaseFlowIcons = {
  auto: '⚡️',
  friend: '👥',
  public: '🌐',
  wlmail: '✉️',
};

export const payoutStatusInfos = {
  [PayoutStatus.Hold]: {
    key : PayoutStatus.Hold,
    icon : 'hourglass_disabled',
    name : 'paiement en attente',
    color: '#ce4358',
  },
  [PayoutStatus.Done]: {
    key : PayoutStatus.Done,
    icon : 'outbond',
    name : 'paiement effectué',
    color: '#3bceac',
  },
  [PayoutStatus.Pending]: {
    key : PayoutStatus.Pending,
    icon : 'hourglass_full',
    name : 'paiement exécuté dans la journée',
    color: '#ce4358',
  },
};

export class SaleRecord extends TicketHolder {
  id: number;
  seller: UserProfile;
  buyer: UserProfile;
  sellerId: number;
  buyerId: number;
  purchaseFlow: PurchaseFlow;
  updatedAt: Date;
  ticketToken: string;
  payoutStatus: PayoutStatus;
  mangoPayInId: string;
  mangoPayOutId: string;
  mangoTransfertId: string;
  Invoice?: Invoice;
  Ticket?: Ticket;
  // properties from extended TicketHolder object
  ticketId: number;
  originalTicketNumber: string;
  originalFirstName: string;
  originalLastName: string;
  newFirstName: string;
  newLastName: string;
  newTicketNumber: string;
  newTicketFileName: string;
  newTicketDowngradeFileName: string;
  Resale?: SaleRecord[];

  constructor(data?: Partial<SaleRecord>) {
    super(data);
    Object.assign(this, data);
    if (data?.Ticket) {
      this.Ticket = new Ticket(data.Ticket);
    }
    if (data?.Invoice) {
      this.Invoice = new Invoice(data.Invoice);
    }
    if (data?.seller) {
      this.seller = new UserProfile(data.seller);
    }
    if (data?.buyer) {
      this.buyer = new UserProfile(data.buyer);
    }
  }
}

export class SeatDetails {
  [SeatingInformation.DateStart]: string | Date;
  [SeatingInformation.Zone]: string;
  [SeatingInformation.Gate]: string;
  [SeatingInformation.Access]: string;
  [SeatingInformation.Row]: string;
  [SeatingInformation.Seat]: string;
  [SeatingInformation.Type]: string;

  constructor(options?: any) {
    if (options) {
      this.zone = options.zone;
      this.gate = options.gate;
      this.access = options.access;
      this.row = options.row;
      this.seat = options.seat;
      this.type = options.type;
      this.dateStart = options.dateStart;
    }
  }
}

export class CategoryPrices {
  minimalPrice: number;
  maximalPrice: number;
}

export class Ticket {
  id: number;
  Category: Category;
  categoryId: number;
  fees: number;
  price: number;
  currency: Currencies;
  status: TicketStatus;
  updatedAt: Date;
  createdAt: Date;
  modifiedBy: number;
  newLastName: string;
  newFirstName: string;
  SaleRecord: SaleRecord;
  seatDetails: SeatDetails;
  customBuyerFields: {[key: string]: string};
  public customTicketAnswers: {[fieldId: number]: unknown};
  Event?: Event;
  eventId: number;
  privateSaleToken: string;
  isFriendSale: boolean;
  directPurchaseToken: string;
  buyerCommercialCommunication: boolean;
  sellerCommercialCommunication: boolean;
  upgradeFees: number;
  upgradeCategoryId: number;
  waitlistToken?: string;
  TicketingPlatform: TicketingPlatform;
  UpgradeCategory: Category;
  TicketRecall: TicketRecall;

  constructor(options?: any) {
    if (options) {
      Object.assign(this, options);

      this.id = options.id || options.ticketId ;
      this.Category = new Category(options.Category);
      this.price = parseInt(options.price, 10);
      this.fees = parseInt(options.fees, 10);
      this.buyerCommercialCommunication = options.buyerCommercialCommunication;
      this.sellerCommercialCommunication = options.sellerCommercialCommunication;
      this.SaleRecord = new SaleRecord(options.SaleRecord);
      this.seatDetails = new SeatDetails(options.seatDetails);
      this.customBuyerFields = options.customBuyerFields;
      this.customTicketAnswers = options.customTicketAnswers;
      this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
      this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
      this.Event = options.Event ? new Event(options.Event) : null;
      this.TicketingPlatform = options.TicketingPlatform ? new TicketingPlatform(options.TicketingPlatform) : undefined;
      this.UpgradeCategory = options.UpgradeCategory ? new Category(options.UpgradeCategory) : undefined;
      this.TicketRecall = options.TicketRecall ? new TicketRecall(options.TicketRecall) : undefined;
    }
  }

  get humanReadableSellerPrice(): string {
    return priceFormatter([this.price]);
  }

  get humanReadableBuyerPrice(): string {
    return priceFormatter([this.price, this.fees, (this.upgradeFees ?? 0)]);
  }

  get buyerPrice(): number {
    return this.price + this.fees + (this.upgradeFees ?? 0);
  }

  public get currencySymbol() {
    return getCurrencySymbol(this.currency);
  }

  humanReadableSeatDetails(translation: Record<string, string>, locale?: string): string {
    return seatFormatter(this.seatDetails, translation, {}, locale);
  }
}
