<ng-container *transloco="let t">
  <p class="fs-16 mb-3" *ngIf="customCheckboxFields?.length > 0">
    {{ t('sale.myTickets.IConfirmInformation') }}
  </p>
</ng-container>
<div *ngFor="let c of customCheckboxFields; let i = index;" class="mb-3">
    <app-checkbox-form-field *ngIf="currentLang === 'fr-FR'"
      class="form__field"
      [form]="form"
      [controlName]="'fr-FR' + i"
      [formModel]="c"
      [validators]="['requiredTrue']"
      [options]="{
        label: c['fr-FR'],
        required: true,
      }"
    >
    </app-checkbox-form-field>
    <p>
      <mat-error *ngIf="currentLang === 'fr-FR' && getErrorCheckbox(form, i)" class="fs-12 error fw-400">{{ 'formErrors.requiredtrue' | transloco }}</mat-error>
    </p>
    <app-checkbox-form-field *ngIf="currentLang === 'es-ES'"
      class="form__field"
      [form]="form"
      [controlName]="'es-ES' + i"
      [formModel]="c"
      [validators]="['requiredTrue']"
      [options]="{
        label: c['es-ES'],
        required: true,
      }"
    >
    </app-checkbox-form-field>
    <p>
      <mat-error *ngIf="currentLang === 'es-ES' && getErrorCheckbox(form, i)" class="fs-12 error fw-400">{{ 'formErrors.requiredtrue' | transloco }}</mat-error>
    </p>
    <app-checkbox-form-field *ngIf="currentLang === 'en-US'"
      class="form__field"
      [form]="form"
      [controlName]="'en-US' + i"
      [formModel]="c"
      [validators]="['requiredTrue']"
      [options]="{
        label: c['en-US'],
        required: true,
      }"
    >
    </app-checkbox-form-field>
    <p>
      <mat-error *ngIf="currentLang === 'en-US' && getErrorCheckbox(form, i)" class="fs-12 error fw-400">{{ 'formErrors.requiredtrue' | transloco }}</mat-error>
    </p>
</div>
