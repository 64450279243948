<mat-form-field
  class="form__field --currency"
  [subscriptSizing]="'dynamic'"
>
  <mat-label>{{options.label}}</mat-label>
  <input
    type="number"
    matInput
    [formControl]="control"
    [min]="options?.min" [max]="options?.max"
    [required]="validators.includes('required')"
    (onWheel)="$event?.preventDefault()"
  >
  <mat-icon
    matSuffix
    class="currency__icon"
    *ngIf="options?.iconName"
  >
    {{ options.iconName }}
  </mat-icon>

  <div
    class="currency__icon"
    matSuffix
  >
    <mat-select
      [formControl]="currencyControl"
      (ngModelChange)="updateCurrency($event)"
      required
    >
      <mat-option *ngFor="let currency of CurrenciesData" [value]="currency.value">
        {{currency.viewValue}}
      </mat-option>
    </mat-select>
  </div>
  <mat-hint>{{options?.hint}}</mat-hint>
  <mat-error *ngIf="control.errors">{{ 'formErrors.' + Objectkeys(control.errors)?.[0] | transloco }}</mat-error>
</mat-form-field>
