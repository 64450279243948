<ng-container *transloco="let te; read 'events'">
  <ng-container *ngIf="['online', 'private'].includes(event?.status); else expiredEvent">

    <div class="section">
      <p class="section__title" *ngIf="!(event.EventPersonalization?.getFieldTrad('platformPageSellTitle', currentLang)); else specialSellTitle">
        {{ te('gotAnExtraTicket') }}
      </p>
      <ng-template #specialSellTitle>
        <p class="section__title">
          {{ event.EventPersonalization?.getFieldTrad('platformPageSellTitle', currentLang) }}
        </p>
      </ng-template>
      <p class="section__text" *ngIf="!(event.EventPersonalization?.getFieldTrad('platformPageSellSubtitle', currentLang)); else specialSellSubtitle">
        <mat-icon class="mr-3">bolt_outline</mat-icon>
        {{ te('sellYourTicket') }}
      </p>
      <ng-template #specialSellSubtitle>
        <p class="section__text">
          {{ event.EventPersonalization?.getFieldTrad('platformPageSellSubtitle', currentLang) }}
        </p>
      </ng-template>
    </div>
    <div class="buttons-group --row --full-width">
      <a data-cy="sale" [routerLink]="['/e/n/', event.url, 'vente']" [target]="widget ? '_blank' : '_self'">
        <button mat-button class="button --custom-event --xlarge --full-width" [ngClass]="{'--widget': widget}">
          {{ te('sellATicket') }}
        </button>
      </a>
    </div>

    <div class="section">
      <p class="section__title" *ngIf="!(event.EventPersonalization?.getFieldTrad('platformPagePurchaseTitle', currentLang)); else specialPurchaseTitle">
        {{ te('lookingForTickets') }}
      </p>
      <ng-template #specialPurchaseTitle>
        <p class="section__title">
          {{ event.EventPersonalization?.getFieldTrad('platformPagePurchaseTitle', currentLang) }}
        </p>
      </ng-template>
      <p class="section__text" *ngIf="!(event.EventPersonalization?.getFieldTrad('platformPagePurchaseSubtitle', currentLang)); else specialPurchaseSubtitle">
        <mat-icon class="mr-3">lock</mat-icon>
        {{ te('getAUniqueTicket') }}
      </p>
      <ng-template #specialPurchaseSubtitle>
        <p class="section__text">
          {{ event.EventPersonalization?.getFieldTrad('platformPagePurchaseSubtitle', currentLang) }}
        </p>
      </ng-template>
    </div>
    <div class="buttons-group --row --full-width">
      <a data-cy="purchase" [routerLink]="['/e/n/', event.url, 'achat']" [target]="widget ? '_blank' : '_self'">
        <button mat-button class="button --custom-event --xlarge --full-width" [ngClass]="{'--widget': widget}">
          {{ te('buyATicket') }}
        </button>
      </a>
    </div>

    <div class="section">
      <p class="section__text" *ngIf="event?.lastTicketSold >= 0">
        <mat-icon class="mr-3">timer</mat-icon>
        {{ te('lastTicketBought') }}
        <ng-container [ngPlural]="event?.lastTicketSold">
          <ng-template ngPluralCase="=0">{{ te('today') }}</ng-template>
          <ng-template ngPluralCase="=1">{{ te('oneDayAgo') }}</ng-template>
          <ng-template ngPluralCase="other">{{ te('daysAgo', {days: event?.lastTicketSold}) }}</ng-template>
        </ng-container>
      </p>
    </div>

  </ng-container>

  <ng-template #expiredEvent>

    <div class="section">
      <mat-card class="fs-16">
        <mat-card-content>
          <p class="fw-700" *ngIf="event.status === EventStatus.Scheduled; else noLongerAvailable"
            [innerHTML]="te('resaleNotYetAvailable')">
          </p>
          <ng-template #noLongerAvailable>
            <p class="fw-700 mb-3" [innerHTML]="te('resaleNoLongerAvailable')"></p>
            <p class="fw-400">{{te('resaleNoLongerAvailableSubtitle')}}</p>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>

  </ng-template>
</ng-container>