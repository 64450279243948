<div class="contact-form" *transloco="let t">

  <ng-container *appLet="(appService.settings.asObservable() | async) as setting">
    <div class="contact-form__element --form">
      <form
        class="form"
        [formGroup]="contactForm"
        (ngSubmit)="onSubmitClick()"
        *ngIf="!sendingDate" 
      >
        <mat-form-field class="form__field" *ngIf="contactForm.controls.fullName">
          <mat-label>{{ t('contact.name')}}</mat-label>
          <input type="text" matInput name="fullName" formControlName="fullName" [appTrim]="true">
          <mat-error *ngIf="getError('fullName')">{{ 'formErrors.' + getError('fullName') | transloco }}</mat-error>
        </mat-form-field>
    
        <p class="mb-2"
          [class]="textSize"
          *ngIf="!contactForm.controls.mailAddress"
        >
          {{ t('contact.answerAddress')}} {{ helpStore.getContactInfo().mailAddress }}
        </p>
    
        <mat-form-field class="form__field" *ngIf="contactForm.controls.mailAddress">
          <mat-label>{{ t('contact.email')}}</mat-label>
          <input type="email" matInput name="mailAddress" formControlName="mailAddress" [appTrim]="true" email required>
          <mat-error *ngIf="getError('mailAddress')">{{ 'formErrors.' + getError('mailAddress') | transloco }}</mat-error>
        </mat-form-field>
    
        <mat-form-field class="form__field" *ngIf="contactForm.controls.subject">
          <mat-label>{{t('contact.subject')}}</mat-label>
          <mat-select matNativeControl name="subject" formControlName="subject" required>
            <mat-option *ngFor="let object of mailObjects" [value]="object">{{ t('contact.' + object)}}</mat-option>
          </mat-select>
          <mat-error *ngIf="getError('subject')">{{ 'formErrors.' + getError('subject') | transloco }}</mat-error>
        </mat-form-field>
    
        <mat-form-field *ngIf="contactForm.controls.event" class="form__field">
          <mat-label>{{ t('contact.eventName')}}</mat-label>
          <input type="text" matInput name="event" formControlName="event" [appTrim]="true" required>
          <mat-error *ngIf="getError('event')">{{ 'formErrors.' + getError('event') | transloco }}</mat-error>
        </mat-form-field>
  
        <ng-container *ngIf="contactForm.controls.missingEventDate">
          <mat-form-field class="form__field">
            <mat-label>{{ t('contact.eventName')}}</mat-label>
            <input type="text" matInput name="event" formControlName="missingEventName" [appTrim]="true" required>
            <mat-error *ngIf="getError('event')">{{ 'formErrors.' + getError('event') | transloco }}</mat-error>
          </mat-form-field>
  
          <mat-form-field class="form__field mb-2">
            <mat-label>{{ t('contact.date')}}</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="Date"
              name="missingEventDate"
              formControlName="missingEventDate"
              (click)="picker?.open()"
            >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint>{{ t('timeUnit.short.date')}}</mat-hint>
          </mat-form-field>
  
          <mat-label>{{ t('contact.iWouldLikeTo') }} *</mat-label>
          <mat-button-toggle-group name="missingEventType" formControlName="missingEventType">
            <mat-button-toggle value="purchase">{{ t('contact.purchaseTicket') }}</mat-button-toggle>
            <mat-button-toggle value="sell">{{ t('contact.sellTicket') }}</mat-button-toggle>
          </mat-button-toggle-group>
          <mat-error *ngIf="getError('missingEventType')">{{ 'formErrors.' + getError('missingEventType') | transloco }}</mat-error>
        </ng-container>
        
        <mat-hint *ngIf="['purchase', 'sell', 'help'].includes(helpStore.getContactInfo().subject)" class="fs-12">
          {{ t('contact.toHelpYouWeNeedInformations')}}
          <ng-container *ngIf="helpStore.getContactInfo().subject === 'sell'">{{ t('contact.barcode')}}, </ng-container>
          ...)
        </mat-hint>
  
        <mat-form-field class="mb-4 form__field">
          <mat-label>{{ t('contact.message')}}</mat-label>
          <textarea
            class="form__field__textarea --long"
            type="text"
            matInput
            name="message"
            formControlName="message"
            [required]="contactForm.value?.subject !== 'missingEvent'"
          ></textarea>
          <mat-error *ngIf="getError('message')">{{ 'formErrors.' + getError('message') | transloco }}</mat-error>
        </mat-form-field>
    
        <p
          *ngIf="!!errors && errors?.length > 0"
          class="error"
          [class]="textSize"
        >
          <ng-container *ngFor="let error of errors; let last = last">
            {{ 'formErrors.' + error | transloco }}
            <br *ngIf="!last">
          </ng-container>
        </p>
        <app-loader *ngIf="isSendingMessage" diameter="40" class="m-auto"></app-loader>
    
        <div class="buttons-group --row --full-width --centered">
          <button class="button --small" [ngClass]="hasCustomColor? '--custom-event' : '--primary'" [disabled]="isSendingMessage" type="submit" mat-button>
            {{ 'button.send' | transloco }}
          </button>
        </div>
    
      </form>
  
      <div *ngIf="!!sendingDate" class="d-flex --centered --column">
  
        <object
          style="width: 20rem"
          class="custom-color-svg"
          type="image/svg+xml"
          [data]="'assets/img/illustration/paper-plane.svg?v=1' + (setting.referer ? '&wref=' + setting.referer : '') | safe:'resourceUrl'"
          *appLet="(colorService.color | async) as color"
        >
          <param name="color" [value]="hasCustomColor ? color : 'url(#reelax-gradient)'">
        </object>
  
        <p
          class="text"
          [class]="textSize"
        >
          {{ t('contact.messageSent')}}
          {{sendingDate | translocoDate: {dateStyle: 'medium', timeStyle:'short' } }}
          <br>
          {{ t('contact.acknowledgementReceiptSentTo')}}
          {{ helpStore.getContactInfo().mailAddress }}
        </p>
  
      </div>
  
    </div>
  
    <div class="contact-form__element --mailbox" *ngIf="withMailBox">
      <ng-container *appLet="(colorService.color | async) as color">
        <object
          extra="origin"
          class="custom-color-svg"
          type="image/svg+xml"
          [data]="'assets/img/illustration/mailbox.svg?v=1' + (setting.referer ? '&wref=' + setting.referer : '') | safe:'resourceUrl'"
        >
          <param name="color" [value]="hasCustomColor ? color : 'url(#reelax-gradient)'">
        </object>
      </ng-container>
  
    </div>
  </ng-container>

</div>