<div [class]="event? (event.isPartner? 'event-cover partner' : 'event-cover') : 'hidden'">
  <div class="event-cover__background">
    <div #backgroundImg class="event-cover__background__image"></div>
  </div>
  <img
    *ngIf="event"
    class="event-cover__foreground-image"
    [alt]="event.name"
    [src]="event.imgUrl ? event.imgUrl : 'assets/img/default-event-cover.jpg'"
  >
  <div *ngIf="event && event.isPartner" class="page-container event-cover__partner">
    <ng-container *transloco="let te; read 'events'">
      <p class="event-cover__partner__title">
        {{ te('officialMarketplace') }}
      </p>
      <p class="event-cover__partner__text" *ngIf="!(event.EventPersonalization?.getFieldTrad('platformPagePartnershipText', currentLang)); else specialText">
        <a class="event-cover__partner__text --link" [routerLink]="['/e', 'n', event.url]">{{partnerName}}</a>
        {{ te('offersSafeTicketBuyAndSale') }}
      </p>
      <ng-template #specialText>
        <p class="event-cover__partner__text">
          {{ event.EventPersonalization?.getFieldTrad('platformPagePartnershipText', currentLang)}}
        </p>
      </ng-template>
  </ng-container>
  </div>
</div>
