<ng-container *transloco="let tav; read 'accountValidation'">
  <ng-container *ngIf="!!form.controls['password'].value">
    <span class="mb-2 d-flex --vertical-centered">{{ tav('pwdStrength')}}
      <ng-container *ngIf="progressSecurisation <= 39; else moreThan39">
        <span class="reelax-red fw-600">{{ tav('weak')}}</span>
      </ng-container>
      <ng-template #moreThan39>
        <ng-container *ngIf="progressSecurisation <= 79 && progressSecurisation > 39; else moreThan79">
          <span class="reelax-orange fw-600">{{ tav('medium')}}</span>
        </ng-container>
      </ng-template>
      <ng-template #moreThan79>
        <span class="reelax-green fw-600">{{ tav('strong')}}</span>
      </ng-template>
      <button type="button" mat-icon-button matSuffix (click)="openDialog()">
        <mat-icon>info</mat-icon>
      </button>
    </span>
    <mat-progress-bar
      mode="determinate"
      [value]="progressSecurisation"
      class="mb-3"
      [ngClass]="{
        '--red': progressSecurisation <= 39,
        '--orange': progressSecurisation <= 79 && progressSecurisation > 39,
        '--green': progressSecurisation > 79,
      }"
    ></mat-progress-bar>
  </ng-container>
</ng-container>
