import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { UntypedFormGroup } from '@angular/forms';
import { PasswordPolicyDialogComponent } from '../password-policy-dialog/password-policy-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;

  public progressSecurisation = 0;

  private formSub: Subscription;

  constructor(
    public navigation: NavigationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.navigation.startSaveHistory();

    const passwordControl = this.form.get('password');
    this.progressSecurisation = this.updateProgressValue(passwordControl.value);
    this.formSub = passwordControl.valueChanges.subscribe((value) => {
      this.progressSecurisation = this.updateProgressValue(value);
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(PasswordPolicyDialogComponent, {
      autoFocus: false,
    });
  }

  private updateProgressValue(pswd: string) {
    if (this.form?.controls?.password?.errors?.inDico || ['laviedeléaestbelle', 'vaGep789#', 'qjippfowgedhbz', 'lavieestbelle']?.includes(pswd)) {
      return 32;
    }
    if (pswd.length > 11 && /.*[a-z].*/.test(pswd)) {
      return 80;
    }
    let count = 0;
    if (/.*[a-z].*/.test(pswd)) {
      count += 16;
    }
    if (/.*[A-Z].*/.test(pswd)){
      count += 16;
    }
    if (/.*[0-9].*/.test(pswd)){
      count += 16;
    }
    if (/.*[!@#$%^&*()_+|{}[\]:;<>,.?~\\-]/.test(pswd)){
      count += 16;
    }
    if (pswd.length > 7){
      count += 16;
    }
    return count;
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
  }

}
