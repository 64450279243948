<ng-container *ngIf="hasOneTranslation">
  <a
    class="link --primary fs-12"
    (click)="displayed = !displayed ; $event.stopPropagation()"
  >{{ (displayed ? 'button.reduce' : 'button.findOutMore') | transloco }}</a>
  <div
    *ngIf="displayed"
    class="fs-12 reelax-dark-grey fw-400 ws-normal"
    [innerHTML]="getTranslatedDescription()?.trim()"
  ></div>
</ng-container>
