<mat-form-field
  class="form__field"
  [subscriptSizing]="'dynamic'"
>
  <mat-label>{{options?.placeholder ?? 'Date'}}</mat-label>
  <input
    matInput
    readonly
    [formControl]="control"
    [required]="validators.includes('required')"
    [min]="options?.minDate"
    [max]="options?.maxDate"
    [matDatepicker]="datepicker"
  >
  <mat-hint>{{options?.hint}}</mat-hint>
  <mat-datepicker-toggle
    *ngIf="!options?.displayClearButton || !control.value"
    [for]="datepicker"
    matSuffix
  ></mat-datepicker-toggle>
  <mat-datepicker-toggle
    *ngIf="options?.displayClearButton && !!control.value"
    matSuffix
    (click)="control?.setValue(null)"
    matTooltip="{{ 'form.clear' | transloco }}"
  >
    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
  </mat-datepicker-toggle>

  <mat-error *ngIf="control.errors">{{ ('formErrors.' + Objectkeys(control.errors)?.[0] ) | transloco }}</mat-error>

  <mat-datepicker
    #datepicker
    [startAt]="options?.startAt"
  >
    <mat-datepicker-actions *ngIf="datepicker.opened">
      <div class="action">
        <div class="action__time">
          <mat-form-field class="action__time__select">
            <mat-label> {{ 'timeUnit.long.hour' | transloco }} </mat-label>
            <mat-select #hoursSelect [formControl]="timeForm.controls.hours">
              <mat-option
                [value]="option"
                *ngFor="let option of selectableHours"
              >
                <span
                  [appScroll]="hoursSelect.panelOpen && hoursSelect.empty && option === 11"
                >
                  {{ showHour(option) }}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="action__time__select">
            <mat-label> {{ 'timeUnit.long.minute' | transloco }} </mat-label>
            <mat-select [formControl]="timeForm.controls.minutes">
              <mat-option
                [value]="option"
                *ngFor="let option of (options?.selectableMinutes ?? selectableMinutes)"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mat-datepicker-actions">
          <button mat-button class="button --small --outlined-primary" matDatepickerCancel>
            {{ 'button.back' | transloco }}
          </button>
          <button mat-raised-button class="button --small --primary" color="primary" matDatepickerApply (click)="apply()">
            {{ 'button.confirm' | transloco }}
          </button>
        </div>
      </div>
    </mat-datepicker-actions>
  </mat-datepicker>

</mat-form-field>
