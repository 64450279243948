import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appScroll]',
})
export class ScrollDirective implements AfterViewInit {
  @Input() set appScroll(scroll: boolean | '') {
    this.scroll = !!scroll;
    this.scrollToElement();
  }
  private scroll = true;
  private isBrowser = false;

  constructor(
    private el: ElementRef,
    private platform: Platform,
  ) {
    this.isBrowser = this.platform.isBrowser;
  }

  ngAfterViewInit() {
    this.scrollToElement();
  }

  private scrollToElement() {
    if (this.isBrowser && this.scroll) {
      setTimeout(() => {
        this.el?.nativeElement?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
      }, (100));
    }
  }

}
