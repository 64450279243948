<mat-checkbox
  class="form__field"
  [name]="controlName"
  [formControl]="control"
>
  <p class="checkbox-text">
    {{ options.label }}
    <span *ngIf="options.required" [matTooltip]="'formErrors.required' | transloco" class="custom-event"> *</span>
  </p>
  <mat-hint
    class="checkbox-hint"
    *ngIf="options.hint"
  >
    {{ options.hint }}
  </mat-hint>
</mat-checkbox>
