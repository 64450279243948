import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Event } from '../../../models';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-events-carousel-vertical',
  templateUrl: './events-carousel-vertical.component.html',
  styleUrls: ['./events-carousel-vertical.component.scss'],
})
export class EventsCarouselVerticalComponent {
  environment = environment;
  @Input() events: Event[] = new Array<Event>();
  @Input() reverse = false;
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) protected platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

}
