import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../transloco/language.service';
import { Language } from '../../../models';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  @Input() hideCurrentLang = false;
  @Input() hideFlag = false;
  public currentLang: Language;
  private translationSub: Subscription;
  public Language = Language;

  constructor(
    private languageService: LanguageService,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.translationSub = this.translocoService.langChanges$.subscribe((lang) => {
      this.currentLang = lang as Language;
    });
  }

  setActiveLang(lang: Language) {
    this.languageService.setLang(lang);
  }

  ngOnDestroy() {
    this.translationSub?.unsubscribe();
  }
}
