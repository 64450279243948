import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[appTrim]',
})
export class TrimDirective {
  @Input() appTrim: boolean | '' = true;

  constructor(private ctrl: NgControl) {
  }

  @HostListener('blur', [
    '$event.target',
    '$event.target.value',
  ])
  onBlur(el: any, value: string): void {
    if (this.appTrim && this.ctrl && value && 'function' === typeof value.trim && value.trim && value.trim() !== value) {
      this.ctrl.control.setValue(value.trim());
    }
  }

}
