import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from '../../utils';
import { Event, UserProfile } from '../../../models';
import { WaitlistInfoDialogComponent } from '../../../my-account/components/waitlist-info-dialog/waitlist-info-dialog.component';
import { AppService } from '../../../services';

@Component({
  selector: 'app-waitlist-gauge',
  templateUrl: './waitlist-gauge.component.html',
  styleUrls: ['./waitlist-gauge.component.scss'],
})
export class WaitlistGaugeComponent {
  private _color: string;
  public backgroundColor: string;
  public randomThreshold = 13;
  @Input() public inPopin = false;
  @Input() public hideIcons = false;
  @Input() public set color(inColor: string) {
    // eslint-disable-next-line no-underscore-dangle
    this._color = inColor;
    const rgbBackground = Utils.transparentToOpaque( inColor + '0D');
    this.backgroundColor = Utils.rgbaToHex(rgbBackground[0], rgbBackground[1], rgbBackground[2], rgbBackground[3]);
    if (this.inPopin) {
      this.backgroundColor = '#ffffff';
    }
  }
  get color() {
    if (!this.currentRank) {
      return '#646473';
    }
    // eslint-disable-next-line no-underscore-dangle
    return this._color;
  }
  @Input() public userRank: number;
  @Input() public currentRank: number;
  @Input() public randomNotifications: number;
  @Input() public user: UserProfile;
  @Input() private event?: Event;
  @Input() private categoryName?: string;

  constructor(
    public dialog: MatDialog,
    public appService: AppService,
  ) {
  }

  openWaitListInfoDialog() {
    this.dialog.open(WaitlistInfoDialogComponent, {
      data: {
        waitListConfig: this.event.WaitListConfig,
        event: {
          color: this.color,
        },
        gauge: {
          myRank: this.userRank,
          currentRank: this.currentRank,
          randomNotifications: this.randomNotifications,
          categoryName: this.categoryName,
        },
        user: this.user,
      },
      autoFocus: false,
    });
  }
}
