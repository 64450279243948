import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputDirective } from '../input';

@Component({
  selector: 'app-status-button',
  templateUrl: './status-button.component.html',
  styleUrls: ['./status-button.component.scss'],
})
export class StatusButtonComponent extends InputDirective {
  @Input() public status: boolean | 'indeterminate' = false;
  @Input() public cssClass = '';
  @Output() clicked = new EventEmitter<boolean>();

  onClick(event: Event) {
    this.clicked.emit(true);
  }

}
