import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';

@Component({
  selector: 'app-numeric-form-field',
  templateUrl: './numeric-form-field.component.html',
  styleUrls: ['./numeric-form-field.component.scss'],
})
export class NumericFormFieldComponent extends FormField<number> implements OnInit, OnDestroy {

  constructor() {
    super();
  }

  public setValue(step: number): void {
    let newValue = (this.control.value ?? 0) + step;
    if (isNaN(newValue)) {
      newValue = step;
    }
    // set value only if matchs min and max
    // min & max must be defined but can be 0
    // if value above or below min or max, set to min or max
    if ( this.hasMax() && newValue > this.options.max) {
      newValue = this.options.max;
    }
    if ( this.hasMin() && newValue < this.options.min) {
      newValue = this.options.min;
    }
    this.control.setValue( newValue );
  }

  public hasMin() {
    return typeof this.options.min !== 'undefined' || this.options.min !== null;
  }

  public hasMax() {
    return typeof this.options.max !== 'undefined' || this.options.max !== null;
  }
}
