import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { NgModule, Inject, PLATFORM_ID, APP_ID, ErrorHandler, importProvidersFrom } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TraceService } from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { MatomoModule } from 'ngx-matomo-client';
import { MatomoRouterConfiguration, MATOMO_ROUTER_CONFIGURATION } from 'ngx-matomo-client';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AlertMessageInterceptor } from './interceptors/alertMessage.interceptor';

// Custom modules
import { SharedModule } from './shared/shared.module';

// Locales
import { registerLocaleData, isPlatformBrowser } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import { NotificationService, RouterService, GlobalErrorHandler } from './services';
import { ServerFallbackComponent } from './pages/server-fallback/server-fallback.component';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SiteComponent } from './components/site/site.component';
import { LoginComponent } from './pages/login/login.component';
import { OrganizerComponent } from './pages/organizer/organizer.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ThreeDsecureComponent } from './pages/three-dsecure/three-dsecure.component';
import { VerifyAccountComponent } from './pages/verify-account/verify-account.component';
import { AlertMessageDialogComponent } from './components/alert-message-dialog/alert-message-dialog.component';
import { RateLimitInterceptor } from './interceptors/rate-limit.interceptor';
import { RefererInterceptor } from './interceptors/referer.interceptor';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefererInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AlertMessageInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RateLimitInterceptor,
    multi: true,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ServerFallbackComponent,
    SiteComponent,
    LoginComponent,
    ThreeDsecureComponent,
    VerifyAccountComponent,
    ResetPasswordComponent,
    HeaderComponent,
    FooterComponent,
    OrganizerComponent,
    AlertMessageDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    TranslocoRootModule,
    MatomoModule.forRoot({
      ...environment.matomo,
    }),
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: MATOMO_ROUTER_CONFIGURATION,
      useValue: {
        exclude: '^/w/', // https://regex101.com/r/OClxuQ/1
      } as MatomoRouterConfiguration,
    },
    RouterService,
    NotificationService,
    importProvidersFrom(HttpClientModule),
    // no http cache prevents issues : https://github.com/angular/angular/issues/50271
    provideClientHydration(withNoHttpTransferCache()),
    [{ provide: APP_ID, useValue: 'reelax-tickets' }],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    trace: TraceService,
  ) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    // eslint-disable-next-line no-console
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
