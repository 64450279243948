import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControlOptions } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { SelectOption } from '../../../../models';
import { FormField } from '../form';


@Component({
  selector: 'app-autocomplete-async-form-field',
  templateUrl: './autocomplete-async-form-field.component.html',
  styleUrls: ['./autocomplete-async-form-field.component.scss'],
})
export class AutocompleteAsyncFormFieldComponent extends FormField<SelectOption> implements OnInit, OnDestroy {
  @Input() public selections$: Observable<SelectOption[]>;
  public filteredSelections: Observable<SelectOption[]>;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.filteredSelections = this.selections$;
  }

  displayFn(pick: SelectOption) {
    if (!pick) {
      return '';
    }
    return pick.viewValue;
  }

  getFormControlOptions(): FormControlOptions {
    return {updateOn: 'change'};
  }

  /*
  TODO use display function to avoid saving whole object
  https://github.com/angular/components/issues/4863

  displayFn(filteredSelections): (pickId: string | number) => string {
    return (pickId: string | number) => {
      if (!pickId || !!filteredSelections) {
        return '';
      }
      console.log(new Date().getTime())
      // const picked = this.selections.find(option => option.value === pickId);
      const picked = this.selections[pickId];
      return picked ? picked.viewValue : 'error';
    };
  }
  */
}
