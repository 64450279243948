
<mat-form-field
  *transloco="let t" 
  [subscriptSizing]="'dynamic'"
>
  <mat-label>
    {{ t(options?.label, options?.labelParam) }}
  </mat-label>
  <input
    type="text"
    matInput
    [formControl]="control"
    [required]="validators.includes('required')"
    [placeholder]="options?.placeholder"
    [appTrim]="options?.trim"
    [appUppercaseSpace4]="validators.includes('uppercaseSpace4')"
  >
  <mat-icon matSuffix *ngIf="options?.iconName"> {{ options.iconName }}</mat-icon>
  <mat-hint>{{ t(options?.hint) }}</mat-hint>
  <mat-hint *ngIf="options?.charNbrHint && options?.maxLength" align="end">{{control?.value?.length || 0}} / {{options.maxLength}}</mat-hint>
  <mat-error *ngIf="control.errors">{{ t('formErrors.' + Objectkeys(control.errors)?.[0] ) }}</mat-error>
</mat-form-field>
