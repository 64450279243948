
// TODO Retrieve currently working api from backend
export enum Platforms {
  Internal = 'internal',
  GenericAsync = 'genericAsync',
  Yurplan = 'yurplan',
  Weezevent = 'weezevent',
  BilletWeb = 'billetweb',
  Wilout = 'wilout',
  Supersoniks = 'supersoniks',
  Rodrigue = 'rodrigue',
  Oneticket = 'oneticket',
  Paylogic = 'paylogic',
  Placeminute = 'placeminute',
  Ressources = 'ressources',
  Soticket = 'soticket',
  Festik = 'festik',
  Seetickets = 'seetickets',
  Digitick = 'digitick',
  Secutix = 'secutix',
  Aparte = 'aparte',
  Sirius = 'sirius',
  Trium = 'trium',
}

export const platformsInfos = {
  [Platforms.Internal]: {
    key : Platforms.Internal,
    icon : '<div class="ticketing-logo --Reelax" title="internal"><span class="material-icons">verified_user</span></div>',
    name : 'internal',
    color: '#242425',
  },
  [Platforms.GenericAsync]: {
    key : Platforms.GenericAsync,
    icon : '<div class="ticketing-logo --Reelax" title="GenericAsync"><svg xmlns="http://www.w3.org/2000/svg" class="img" viewBox="80 -800 800 640"><path d="M230-360h120v-60H250v-120h100v-60H230q-17 0-28.5 11.5T190-560v160q0 17 11.5 28.5T230-360Zm156 0h120q17 0 28.5-11.5T546-400v-60q0-17-11.5-31.5T506-506h-60v-34h100v-60H426q-17 0-28.5 11.5T386-560v60q0 17 11.5 30.5T426-456h60v36H386v60Zm264 0h60l70-240h-60l-40 138-40-138h-60l70 240ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z"/></svg></div>',
    name : 'GenericAsync',
    color: '#3bceac',
  },
  [Platforms.Yurplan]: {
    key : Platforms.Yurplan,
    icon : '<div class="ticketing-logo --Yurplan" title="Yurplan"><img src="assets/img/companies/yurplan-logo.svg?v=1"></div>',
    name : 'Yurplan',
    color: '#f84904',
  },
  [Platforms.Weezevent]: {
    key : Platforms.Weezevent,
    icon : '<div class="ticketing-logo --Weezevent" title="Weezevent"><img src="assets/img/companies/weezevent-logo.svg"></div>',
    name : 'Weezevent',
    color: '#0032fa',
  },
  [Platforms.BilletWeb]: {
    key : Platforms.BilletWeb,
    icon : '<div class="ticketing-logo --Billetweb" title="Billetweb"><img src="assets/img/companies/billetweb-logo.svg?v=1"></div>',
    name : 'BilletWeb',
    color: '#151756',
  },
  [Platforms.Soticket]: {
    key : Platforms.Soticket,
    icon : '<div class="ticketing-logo --SoTicket" title="SoTicket"><img src="assets/img/companies/soticket-logo.png"></div>',
    name : 'Soticket',
    color: '#F7213C',
  },
  [Platforms.Supersoniks]: {
    key : Platforms.Supersoniks,
    icon : '<div class="ticketing-logo --SoTicket"><img src="assets/img/companies/soticket-logo.png"></div>',
    name : 'Supersoniks',
    color: '#F7213C',
  },
  [Platforms.Rodrigue]: {
    key : Platforms.Rodrigue,
    icon : '<div class="ticketing-logo --Rodrigue" title="Rodrigue"><img src="assets/img/companies/rodrigue-logo.png"></div>',
    name : 'Rodrigue',
    color: '#2e3757',
  },
  [Platforms.Wilout]: {
    key : Platforms.Wilout,
    icon : '<div class="ticketing-logo --Wilout" title="Wilout"><img src="assets/img/companies/wilout-logo.svg"></div>',
    name : 'Wilout',
    color: '#D04014',
  },
  [Platforms.Festik]: {
    key : Platforms.Festik,
    icon : '<div class="ticketing-logo --Festik" title="Festik"><img src="assets/img/companies/festik-logo.svg"></div>',
    name : 'Festik',
    color: '#0F0E03',
  },

};

export const platformWithCategoryForeignIds = [
  Platforms.Weezevent,
  Platforms.Yurplan,
  Platforms.BilletWeb,
  Platforms.Wilout,
  Platforms.Festik,
  Platforms.Supersoniks,
  Platforms.Oneticket,
  Platforms.Rodrigue,
];

export class TicketingPlatform {
  public platform: Platforms;
  public platformEventId: string;
  public platformOrganizerId?: string;
  public payoutAuto?: boolean;
  public purchaseDisabled?: boolean;
  public resaleSameTicketMaxAmount = 0;
  public saleDisabled?: boolean;
  public id?: number;
  public customRegex?: string;

  // foreign keys properties
  public eventId: number;

  constructor(data: Partial<TicketingPlatform>) {
    Object.assign(this, data);
  }
}

export const PlatformsRegex: {[key: string]: RegExp} = {
  yurplan: /^(69)\d+$/,
  weezevent: /^(66)\d+$/,
  billetweb: /^(?!00000)([0-9]{6,10})+$/,
  wilout: /^()[a-zA-Z0-9]+$/,
  genericAsync: /^()[a-zA-Z0-9]+$/,
  internal: /.*/,
  oneticket: /^(kinora)[-a-zA-Z\d]+$/,
  festik: /^()[a-zA-Z0-9- ]+$/,
};
