import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-basic',
  templateUrl: './dialog-basic.component.html',
  styleUrls: ['./dialog-basic.component.scss'],
})
export class DialogBasicComponent {

  public currentZoom = 1;
  @ViewChild('img') img: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogBasicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      text?: string,
      imageSrc?: string,
      zoom?: boolean,
    },
  ) { }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  zoomIn() {
    this.currentZoom = this.currentZoom + 1;
    this.img.nativeElement.style.transform = `scale(${this.currentZoom})`;
  }

  zoomOut() {
    this.currentZoom = this.currentZoom - 1;
    this.img.nativeElement.style.transform = `scale(${this.currentZoom})`;
  }

}
