import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../services';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  constructor(
    public navigation: NavigationService,
  ) { }

  ngOnInit(): void {
    this.navigation.startSaveHistory();
  }

}
