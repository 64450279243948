<ng-container *transloco="let twc; read 'waitlist.confirmation'">
  <ng-container *ngIf="waitListPurchase?.purchaseAutoEnabled; then purchaseAuto; else emailAlerts"></ng-container>
  <!-- if purchase auto enabled -->
  <ng-template #purchaseAuto>
    <ng-container
      *ngIf="getTotalPurchasedTickets() < waitListStore.getEntriesWithStatus(this.event.id, true)?.[0]?.quantity &&
        getTotalPurchasedTickets() - getManuallyPurchasedTickets() < getAskedAutoPurchaseNumber()"
    >
      <span [innerHTML]="twc('activatedAutoPurchaseForTickets', { count: getAskedAutoPurchaseNumber() })"></span>
      <ng-container [ngPlural]="getAskedAutoPurchaseNumber()">
        <ng-template ngPluralCase="=1">
          <span [innerHTML]="twc('ticket')"></span>
        </ng-template>
        <ng-template ngPluralCase="other">
          <span [innerHTML]="twc('tickets')"></span>
        </ng-template>
        {{ twc('automatically') }}.
      </ng-container>
      <br>
      <br *ngIf="numberOfOptions > 0">
    </ng-container>
    <ng-container *ngIf="numberOfOptions > 0">
      {{ numberOfOptions > 1 ? twc('activatedOptions') : twc('activatedOption') }}
      <ng-container *ngFor="let option of options | keyvalue">
        <ng-container *ngIf="option.value">
          <br>
          <span class="custom-event">• </span>
          <ng-container *ngIf="getTotalPurchasedTickets() > 0; then alreadyGotTickets; else noTicketsYet"></ng-container>
          <ng-template #alreadyGotTickets>
            <span [innerHTML]="twc(option.key === 'allOrNone' ? 'allAdditionalOrNone' : option.key, {quantity: getAskedAutoPurchaseNumber() - getTotalPurchasedTickets()})"></span>
          </ng-template>
          <ng-template #noTicketsYet>
            <span [innerHTML]="twc(option.key, {quantity: getAskedAutoPurchaseNumber()})"></span>
          </ng-template>
        </ng-container>
      </ng-container>
      <br><br>
    </ng-container>
    <ng-container *ngIf="getTotalPurchasedTickets() > 0; then alreadyGotTickets; else noTicketsYet"></ng-container>
    <ng-template #alreadyGotTickets>
      <span [innerHTML]="twc('alreadyReceived', { count: getTotalPurchasedTickets() })"></span>
      <ng-container [ngPlural]="getTotalPurchasedTickets()">
        <ng-template ngPluralCase="=1">
          <span [innerHTML]="twc('ticket')"></span>
        </ng-template>
        <ng-template ngPluralCase="other">
          <span [innerHTML]="twc('tickets')"></span>
        </ng-template>
        <span> (</span>
        <ng-container *ngIf="getManuallyPurchasedTickets() > 0">
          <span>{{ twc('manually', { count: getManuallyPurchasedTickets() }) }}</span>
        </ng-container>
        <ng-container *ngIf="getManuallyPurchasedTickets() > 0 && getManuallyPurchasedTickets() < getTotalPurchasedTickets()">
          <span> {{ twc('and') }} </span>
        </ng-container>
        <ng-container *ngIf="getManuallyPurchasedTickets() < getTotalPurchasedTickets()">
          <span>{{ twc('automatically', { count: getTotalPurchasedTickets() - getManuallyPurchasedTickets() }) }}</span>
        </ng-container>
        <span>).</span>
      </ng-container>
      <br>
      <ng-container *ngIf="getTotalPurchasedTickets() - getManuallyPurchasedTickets() < getAskedAutoPurchaseNumber(); then stillWaitingTickets; else gotAllTickets">
      </ng-container>
      <ng-template #stillWaitingTickets>
        <br>
        <span [innerHTML]="twc('whenYourTurnYouWillBeCharged')"></span>
        <ng-container [ngPlural]="getAskedAutoPurchaseNumber() - (getTotalPurchasedTickets() - getManuallyPurchasedTickets())">
          <ng-template ngPluralCase="=1">
            <span [innerHTML]="twc('theRemainingTicket')"></span>
          </ng-template>
          <ng-template ngPluralCase="other">
            <span [innerHTML]="twc('theRemainingTickets')"></span>
          </ng-template>
        </ng-container>
        <span [innerHTML]="twc('byEmailIfAvailable')"></span>
      </ng-template>
      <ng-template #gotAllTickets>
        <br>
        <span [innerHTML]="twc('youWillNotBeChargedAndWontReceiveAnyMoreTickets')"></span>
      </ng-template>
    </ng-template>
    <ng-template #noTicketsYet>
      <span [innerHTML]="twc('whenYourTurnYouWillBeCharged')"></span>
      <ng-container [ngPlural]="getAskedAutoPurchaseNumber()">
        <ng-template ngPluralCase="=1">
          <span [innerHTML]="twc('yourTicket')"></span>
        </ng-template>
        <ng-template ngPluralCase="other">
          <span [innerHTML]="twc('yourTickets')"></span>
        </ng-template>
      </ng-container>
      <span [innerHTML]="twc('byEmailIfAvailable')"></span>
    </ng-template>
  </ng-template>
  <!-- if email alert enabled -->
  <ng-template #emailAlerts>
    <ng-container *ngIf="getTotalPurchasedTickets() < waitListStore.getEntriesWithStatus(this.event.id, true)?.[0]?.quantity; else noMoreEmailAvailable">
      <span [innerHTML]="twc('weWillSendYouEmailIfTicketAvailable')"></span><br>
      <span [innerHTML]="twc('ticketWillOnlyBeAvailableForWaiters', {reservedTime: msToTime(waitListConfig.reservedTime)})"></span>
    </ng-container>
    <ng-template #noMoreEmailAvailable>
      <span [innerHTML]="twc('youWillNotBeAlerted')"></span>
    </ng-template>
  </ng-template>
</ng-container>

<ng-container *transloco="let twp; read 'waitlist.purchaseAuto'">
  <ng-container *ngIf="getTotalPurchasedTickets() < event.WaitListConfig?.maxTicketQuantity
    && getTotalPurchasedTickets() >= waitListStore.getEntriesWithStatus(this.event.id, true)?.[0]?.quantity">
    <br>
    <br>
    <span class="reelax-red">{{ twp('mustIncreaseAskedQuantity') }}</span>
  </ng-container>
  <ng-container *ngIf="getTotalPurchasedTickets() >= event.WaitListConfig?.maxTicketQuantity">
    <br>
    <br>
    <span class="reelax-red">{{ twp('alreadyGotMaxQuantity') }}</span>
  </ng-container>
</ng-container>
