import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { EncryptionService, FingerprintService } from '../services';

@Injectable()
export class FingerprintInterceptor implements HttpInterceptor {

  constructor(
    private fingerprintService: FingerprintService,
    private encryptionService: EncryptionService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.url.includes('/api')) {
      return next.handle(req);
    }

    return from(this.fingerprintService.get()).pipe(
      mergeMap( (fp) => {
        const content = req.detectContentTypeHeader();
        if (content === 'application/json') {
          return next.handle(req.clone({
            body: {
              ...req.body,
              fp: this.encryptionService.encrypt(fp),
            },
            setHeaders: { 'Reelax-FP': fp.id },
          }));
        } else {
          return next.handle(req.clone({
            setHeaders: { 'Reelax-FP': fp.id },
          }));
        }

      }));
  }
}
