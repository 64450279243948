<div class="carousel" (mouseenter)="stop()" (mouseleave)="run()">
  <mat-icon *ngIf="buttons && items?.length >= 1" class="carousel__button --previous" (click)="previous()">keyboard_arrow_left</mat-icon>

  <div #carousel_container class="carousel__container">
    <div class="carousel__item" *ngFor="let item of items;" [style.transform]="getTransform()">
      <ng-container *ngTemplateOutlet="template; context: {item: item}"></ng-container>
    </div>
  </div>

  <mat-icon *ngIf="buttons && items?.length >= 1" class="carousel__button --next" (click)="next()">keyboard_arrow_right</mat-icon>
</div>
