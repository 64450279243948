import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';

@Component({
  selector: 'app-text-form-field',
  templateUrl: './text-form-field.component.html',
  styleUrls: ['./text-form-field.component.scss'],
})
export class TextFormFieldComponent extends FormField<string> implements OnInit, OnDestroy {

  constructor() {
    super();
  }

}
