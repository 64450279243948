import { Component } from '@angular/core';
import { press } from '../../../../assets/ressources/press';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-all-press-articles-carousel',
  templateUrl: './all-press-articles-carousel.component.html',
  styleUrls: ['./all-press-articles-carousel.component.scss'],
})
export class AllPressArticlesCarouselComponent {
  press = press;

  // item size must be in px without unit
  public itemSize = '240';
  private isBrowser = false;

  constructor(
    private platform: Platform,
  ) {
    this.isBrowser = this.platform.isBrowser;
    if (this.isBrowser) {
      const mediaQuery = window.matchMedia('(min-width: 576px)');
      if (mediaQuery.matches) {
        this.itemSize = '300';
      }
    }
  }

}
