import { Component, Input } from '@angular/core';
import { AppService, ColorService } from '../../../services';

@Component({
  selector: 'app-reelax-tickets-logo',
  templateUrl: './reelax-tickets-logo.component.html',
  styleUrls: ['./reelax-tickets-logo.component.scss'],
})
export class ReelaxTicketsLogoComponent {
  @Input() public isBurger = false;

  constructor(
    public colorService: ColorService,
    public appService: AppService,
  ) { }

}
