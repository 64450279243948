<div class="page-container margins" [appHelp]="['buyer']">

  <div *ngIf="isCheckingPayment">
    <p class="loading-text subtitle --center" *transloco="let tpp; read 'purchase.processing'">
      {{ tpp('transactionProcessingPleaseWait') }}
    </p>
    <app-loader diameter="60" class="m-auto my-4"></app-loader>
    <div *ngIf="this.quote1">
      <p class="loading-text--quote">
        {{ this.quote1.quote }}
      </p>
      <p class="loading-text--quote">
        {{ this.quote1.author }}
      </p>
    </div>
    <div *ngIf="this.quote2">
      <p class="loading-text--quote">
        {{ this.quote2.quote }}
      </p>
      <p class="loading-text--quote">
        {{ this.quote2.author }}
      </p>
    </div>
  </div>

  <ng-container *transloco="let tpe; read 'purchase.error'">
    <div *ngIf="!!payementFailed || !!errorMessage" class="error-card__column error">
      <mat-icon class="reelax-red">error</mat-icon> 
      <div *ngIf="!!payementFailed" class="mb-4">
        <p class="error-card__subtitle mb-4 fs-20 fw-700">{{ tpe('paymentFailure') }}</p>
        <p class="fs-16">{{ tpe('bankAccountNotDebited') }}</p>
      </div>
      <div *ngIf="!!errorMessage" class="mb-4">
        <p [ngClass]="{'error-card__subtitle mb-4 fs-20 fw-700' : !payementFailed}">{{ errorMessage }}</p>
        <p>{{ errorInfo }}</p>
      </div>
      <div *ngIf="!!payementFailed" class="buttons-group --row --centered">
        <button *ngIf="ticketId" mat-button class="button --primary" [routerLink]="['/evenement/', eventId, 'achat', ticketId, '3']">
          {{ tpe('retryMyPayment') }}
        </button>
        <button *ngIf="wlPurchaseId" mat-button class="button --primary" [routerLink]="['/evenement/', eventId, 'achat', 'attente', '3']">
          {{ tpe('retryMyPayment') }}
        </button>
      </div>
    </div>
  </ng-container>

  <div *ngIf="waiting" class="buttons-group --row --centered">
    <button *ngIf="ticketId" mat-button class="button --primary" (click)="doPaymentCheck()">
      {{ 'button.update' | transloco }}
    </button>
  </div>

  <p class="text --normal" *ngIf="payementFailed || errorMessage" [innerHTML]="'formErrors.errorPersist' | transloco"></p>

</div>