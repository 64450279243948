import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';

@Component({
  selector: 'app-radiogroup-form-field',
  templateUrl: './radiogroup-form-field.component.html',
})
export class RadiogroupFormFieldComponent extends FormField<any> implements OnInit, OnDestroy {
  @Input() public radioOptions: {value, viewValue: string, disabled?: boolean}[];

  constructor() {
    super();
  }

}
