import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-saving-indicator',
  templateUrl: './saving-indicator.component.html',
  styleUrls: ['./saving-indicator.component.scss'],
})
export class SavingIndicatorComponent {
  @Input() public pendingSave = false;
  @Input() public saving = false;
  @Input() public saved = false;
}
