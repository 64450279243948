import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { CustomTicketField, Language, LanguageMap } from '../../../models';
import { Utils } from '../../utils';

@Component({
  selector: 'app-custom-ticket-fields',
  templateUrl: './custom-ticket-fields.component.html',
  styleUrls: ['./custom-ticket-fields.component.scss'],
})
export class CustomTicketFieldsComponent implements OnInit {
  @Input() JSONfields: {[index: number]: CustomTicketField};
  @Input() eventId: number;
  customTicketFields: {[index: number]: CustomTicketField};
  formGroup = new FormGroup({});
  @Input() public memorisedValues: {customTicketAnswers?: {[fieldId: number]: unknown}};
  @Input() set form(parentForm: FormGroup) {
    if (!parentForm.contains('customTicketAnswers')) {
      parentForm.addControl('customTicketAnswers', this.formGroup);
    }
  }

  public Language = Language;
  public languages = Object.values(this.Language);

  constructor(
    private translocoService: TranslocoService,
  ) {
  }

  ngOnInit(): void {
    this.customTicketFields = this.JSONfields;
    if (!this.memorisedValues?.customTicketAnswers) {
      this.memorisedValues.customTicketAnswers = {};
    }
  }

  getName(names: LanguageMap) {
    return Utils.getTranslation(names, this.translocoService.translate('language.localeCountry'));
  }

}
