<mat-sidenav-container
  class="full-height"
  [ngClass]="appService.settings?.value.isWidget ? 'custom-background-color' : 'reelax-light-grey-bg'"
>
  <mat-sidenav-content class="page">

    <div #header class="header">
      <app-header></app-header>
    </div>

    <div class="content" [ngClass]="isWidget ? 'content-widget': 'content'">
      <router-outlet></router-outlet>
      <ng-container *ngIf="loadingRouteModule">
        <div class="loader">
          <app-loader diameter="120" class="loader__spinner"></app-loader>
        </div>
      </ng-container>
    </div>
  
    <app-footer class="footer"></app-footer>
  </mat-sidenav-content>

</mat-sidenav-container>
