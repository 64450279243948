<ng-container *transloco="let tu; read 'uploader'">
	<ngx-dropzone 
		(change)="onSelect($event)"
		[accept]="acceptedFiles"
		[maxFileSize]="options.maxSize"
		[expandable]="!options.tiny" 
		class="drop-zone"
		[class.--tiny]="options.tiny"
	>
		<ngx-dropzone-label *appLet="(appService.settings.asObservable() | async) as setting">
			<object
				*ngIf="!options.tiny"
				type="image/svg+xml"
				[data]="'assets/img/illustration/upload.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
				class="upload-img"
			>
				<param name="color" [value]="illustrationColor">  
			</object>
			<p [class.tiny-text]="options.tiny">
				{{ tu('clickOrDragFilesHere') }}
			</p>
			<p *ngIf="!options.tiny">
				{{ tu('acceptedDocuments', {acceptedFilesDisplay}) }}
			</p>
		</ngx-dropzone-label>
		<ng-container *ngIf="!options.tiny" >
			<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
				<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
			</ngx-dropzone-image-preview>
		</ng-container>
		<ng-container *ngIf="options.tiny" >
			<app-uploader-tiny-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
				<ngx-dropzone-label class="no-click">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
			</app-uploader-tiny-preview>
		</ng-container>
	</ngx-dropzone>

	<p *ngIf="!!error" class="error fs-16 center mx-0 my-3">{{error}}</p>
</ng-container>