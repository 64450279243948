<div class="form custom-text-color" *ngIf="this.memorisedValues?.customTicketAnswers">
  <ng-container *ngFor="let field of customTicketFields | keyvalue">

    <app-text-form-field
      *ngIf="field.value.type === 'string'"
      class="form__field --large"
      [form]="formGroup"
      [controlName]="field.key"
      [formModel]="memorisedValues.customTicketAnswers"
      [validators]="[ field.value.isRequired ? 'required' : '']"
      [options]="{
        label: getName(field.value.name),
        trim: true
      }"
    ></app-text-form-field>

    <app-numeric-form-field
      *ngIf="field.value.type === 'number'"
      class="form__field --large"
      [form]="formGroup"
      [controlName]="field.key"
      [formModel]="memorisedValues.customTicketAnswers"
      [validators]="[ field.value.isRequired ? 'required' : '']"
      [options]="{
        label: getName(field.value.name),
      }"
    ></app-numeric-form-field>
    
    <ng-container *ngIf="field.value.type === 'boolean'">
      <app-checkbox-form-field
        class="form__field mb-2"
        [form]="formGroup"
        [controlName]="field.key"
        [formModel]="memorisedValues.customTicketAnswers"
        [validators]="[ field.value.isRequired ? 'requiredTrue' : '']"
        [options]="{
          label: getName(field.value.name),
          required: field.value.isRequired,
        }"
      >
      </app-checkbox-form-field>
    </ng-container>

    <ng-container *ngIf="field.value.type === 'date'">

      <app-date-form-field
        [controlName]="field.key"
        [form]="formGroup"
        [formModel]="memorisedValues.customTicketAnswers"
        [validators]="[ field.value.isRequired ? 'required' : '']"
        [options]="{
          label: getName(field.value.name),
        }"
      >
      </app-date-form-field>

    </ng-container>
  </ng-container>
  
</div>
