import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-error-controls',
  templateUrl: './error-controls.component.html',
  styleUrls: ['./error-controls.component.scss'],
})
export class ErrorControlsComponent {
  @Input() form: FormGroup | FormArray;

  public findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray ): string[] {
    const invalidControls: string[] = [];
    const recursiveFunc = (form: FormGroup | FormArray) => {
      if (form.valid) {
        return;
      }
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control instanceof FormControl && control.invalid) {
          invalidControls.push(field);
        }
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

}
