import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { AppService, ColorService } from '../../../services';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent implements OnInit, OnDestroy {
  files: File[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public _acceptedFiles;
  public acceptedFilesDisplay;
  @Input()
  set acceptedFiles(filesType: string) {
    // eslint-disable-next-line no-underscore-dangle
    this._acceptedFiles = filesType;
    this.acceptedFilesDisplay = filesType.split(',').map((s) => s.replace('.', '') ).join(', ').toUpperCase();
  }
  get acceptedFiles() {
    // eslint-disable-next-line no-underscore-dangle
    return this._acceptedFiles;
  }
  @Input() public options = {
    maxSize: 7000000, // in bytes
    minSize: 32000, // in bytes
    tiny: false,
  };
  illustrationColor: string;

  translationSub: Subscription;
  error = '';

  @Output() fileChange = new EventEmitter<File[]>();

  constructor(
    public appService: AppService,
    private translocoService: TranslocoService,
    private colorService: ColorService,
  ) { }

  ngOnInit() {
    const customColor = this.colorService.getEventColor();
    const defaultColor = this.colorService.defaultColor;
    this.illustrationColor = (!customColor || customColor === defaultColor) ? 'url(#reelax-gradient)' : customColor;

    this.translationSub = this.translocoService.selectTranslateObject('uploader.error').subscribe();
  }

  onSelect(event) {
    this.error = '';
    if (event.rejectedFiles && event.rejectedFiles.length > 0) {
      const name = event.rejectedFiles[0].name;
      if (event.rejectedFiles[0].reason === 'size') {
        this.error = this.translocoService.translate('uploader.error.maxSizeReached', {name});
      } else if (event.rejectedFiles[0].reason === 'type') {
        this.error = this.translocoService.translate('uploader.error.forbiddenType', {name});
      } else {
        const reason = event.rejectedFiles[0].reason;
        this.error = this.translocoService.translate('uploader.error.notValid', {name, reason});
      }
    } else {
      // catch and show minimalSize error
      event?.addedFiles?.forEach((file: File) => {
        if (file.size < this.options.minSize) {
          this.error = this.translocoService.translate('uploader.error.minSizeNotReached', {name: file.name});
        }
      });
    }
    if (this.error) {
      return;
    }
    this.files.push(...event.addedFiles);
    this.fileChange.emit(this.files);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.fileChange.emit(this.files);
  }

  ngOnDestroy() {
    this.translationSub?.unsubscribe();
  }
}
