import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-partner-card',
  templateUrl: './partner-card.component.html',
  styleUrls: ['./partner-card.component.scss'],
})
export class PartnerCardComponent implements OnChanges {
  environment = environment;
  @Input() partner: {imgUrl: string, name: string, website: string};
  isLoaded = false;

  constructor() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.partner) {
      this.isLoaded = false;
    }
  }

  handleLoading() {
    this.isLoaded = true;
  }

}
