import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { SafePipe } from './safe.pipe';
import { TicketValidityPipe } from './ticket-validity.pipe';
import { UrlPipe } from './urlReducer.pipe';
import { MangopayIdPipe } from './mangopayId.pipe';
import { TranslateLanguageMapPipe } from './translateLanguageMap.pipe';
import { FixedDecimalPipe } from './fixed-decimal.pipe';

@NgModule({
  declarations: [
    FilterPipe,
    SafePipe,
    TicketValidityPipe,
    UrlPipe,
    MangopayIdPipe,
    FixedDecimalPipe,
    TranslateLanguageMapPipe,
  ],
  exports: [
    FilterPipe,
    SafePipe,
    TicketValidityPipe,
    UrlPipe,
    MangopayIdPipe,
    FixedDecimalPipe,
    TranslateLanguageMapPipe,
  ],
})
export class PipesModule {}
