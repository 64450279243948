import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDateFormats, MAT_NATIVE_DATE_FORMATS, MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { FormField } from '../form';
import { DatepickerTranslation } from '../../../material-overriders';

const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    } as Intl.DateTimeFormatOptions,
  },
};

@Component({
  selector: 'app-date-time-form-field',
  templateUrl: './date-time-form-field.component.html',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
    { provide: DateAdapter, useClass: DatepickerTranslation },
  ],
  styleUrls: ['./date-time-form-field.component.scss'],
})
export class DateTimeFormFieldComponent extends FormField<Date> implements OnInit, OnDestroy {

  public selectableHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20 , 21, 22, 23];
  public selectableMinutes = [ 0, 15, 30, 45, 59 ];
  public timeForm = new FormGroup({
    hours: new FormControl(),
    minutes: new FormControl(),
  });

  private localeSub: Subscription;

  constructor(
    private translocoService: TranslocoService,
    private adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private locale: string,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.locale = this.translocoService.getActiveLang();
    this.adapter.setLocale(this.locale);
    this.localeSub = this.translocoService.langChanges$.subscribe((lang) => {
      this.locale = lang;
      this.adapter.setLocale(this.locale);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.localeSub?.unsubscribe();
  }

  setFormModel(m) {
    if (m[this.controlName]) {
      const modelDate = new Date(m[this.controlName]);
      this.timeForm.controls.hours.setValue( modelDate.getHours() );
      this.timeForm.controls.minutes.setValue( modelDate.getMinutes() );
    }
    super.setFormModel(m);
  }

  showHour(hour: number) {
    const date = new Date();
    date.setHours(hour);
    return date.toLocaleString(this.translocoService.translate('language.locale'), { hour: 'numeric'});
  }

  apply() {
    const timeChange = this.timeForm.value;
    const dateTimeModel = this.getFormModelValue(this.formModel, this.controlName);
    if (dateTimeModel instanceof Date) {
      dateTimeModel.setHours(timeChange.hours);
      dateTimeModel.setMinutes(timeChange.minutes);
      this.setFormModel({[this.controlName]: dateTimeModel});
    }
  }

}
