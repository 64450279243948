<mat-card class="social-card" *transloco="let tsp; read 'socialPreview'">
  <img class="social-card__img" [src]="seoService.getSocialImgLink(event?.imgUrl)">
  <div class="social-card__text">
    <p class="social-card__text__website">REELAX-TICKETS.COM</p>
    <p class="social-card__text__title" *ngIf="type === 'sell'">{{tsp('resellTicketsFor')}} {{event.name}}</p>
    <p class="social-card__text__title" *ngIf="type === 'buy'">
      <ng-container *ngIf="ticket.price; else noPrice">
        {{ tsp('buyATicketAtPriceFor', {
          price: (ticket.buyerPrice)/100 | number: '1.2-2':('language.locale' | transloco),
          currency: ticket.currencySymbol
        }) }} {{event.name}}
      </ng-container>
      <ng-template #noPrice>
        {{ tsp('buyATicketFor') }} {{event.name}}
      </ng-template>
    </p>
    <p class="social-card__text__description">
      {{event.organizerName ?? event.name}} {{ tsp('hasEnteredIntoAPartnershipWithReelax') }}
    </p>
  </div>
</mat-card>
