
<mat-form-field
  class="form__field"
  *transloco="let t"
  [subscriptSizing]="'dynamic'"
>
  <mat-label>{{ options?.label }}</mat-label>
  <input
    #input
    type="text"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    [required]="validators.includes('required')"
  >
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option
      *ngFor="let option of filteredSelections | async"
      [value]="option"
      [disabled]="option.disabled"
      (click)="input.blur()"
    >
      {{ option.viewValue }}
    </mat-option>
  </mat-autocomplete>
  <mat-icon matSuffix *ngIf="options?.iconName"> {{ options.iconName }}</mat-icon>
  <mat-hint>{{options?.hint}}</mat-hint>
  <mat-error *ngIf="control?.errors">{{ t('formErrors.' + Objectkeys(control.errors) ) }}</mat-error>
</mat-form-field>
