import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-calendar-icon',
  templateUrl: './calendar-icon.component.html',
  styleUrls: ['./calendar-icon.component.scss'],
})
export class CalendarIconComponent {
  @Input() public date: Date;

}
