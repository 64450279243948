import { Event } from './event.model';
import { Ticket } from './ticket.model';

export class Bucket {
  eventId: number;
  eventName: string;
  step: string;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
  ticketId: number;
  id: string;
  Event: Event;
  Ticket: Ticket;
  serverTime?: Date;
  expirationTime?: Date;

  constructor(options?: any) {
    if (options) {
      this.eventId = options.eventId;
      this.step = options.step;
      this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
      this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
      this.userId = options.userId;
      this.id = options.id;
      this.eventName = options.eventName;
      this.ticketId = options.ticketId;
      this.Event = options.Event;
      this.Ticket = options.Ticket ? new Ticket(options.Ticket) : undefined;
      this.serverTime = options.serverTime ? new Date(options.serverTime) : undefined;
    }
  }
}
