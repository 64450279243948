import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EventsService } from '../../services';
import { EventStore } from '../../stores';
import { Event } from '../../models';

// todo migrate to resolve function
// How to handle the currentEvent ? => user service ?
@Injectable({
  providedIn: 'root',
})
export class PublicEventResolver  {
  private currentEvent: Event;

  constructor(
    private router: Router,
    private eventsService: EventsService,
    private eventStore: EventStore,
  ) {  }

  resolve(route: ActivatedRouteSnapshot) {
    const eventId = route.pathFromRoot.find((path) => !!path.params.eventId)?.params?.eventId;
    const eventUrl = route.pathFromRoot.find((path) => !!path.params.eventUrl)?.params?.eventUrl;
    if (this.currentEvent && (this.currentEvent.id === eventId || this.currentEvent.url === eventUrl)) {
      this.eventStore.setCurrentEvent(this.currentEvent.id);
      return of(this.currentEvent);
    }
    return this.eventsService.getEventIfAvailable(eventId, eventUrl).pipe(
      tap((e) => {
        this.currentEvent = new Event(e);

        this.eventStore.loadEvents([this.currentEvent]);
        this.eventStore.setCurrentEvent(this.currentEvent.id);

        const currentUrl = route.pathFromRoot.reduce((url, route) => [url, ...route.url.map((s) => s.toString())].filter((s) => !!s).join('/'), '/');
        const basePath = currentUrl.substring(
          0,
          currentUrl.indexOf(this.currentEvent.url) + this.currentEvent.url.length,
        );
        if (!['online', 'private'].includes(this.currentEvent.status) && currentUrl !== basePath) {
          this.router.navigate([basePath]);
        }
      }),
      catchError((err) => {
        this.router.navigate(['404']);
        return EMPTY;
      }),
    );
  }

}
