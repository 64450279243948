<div class="page-container">

  <ng-container *ngIf="organizer.logoUrl; else organizerName">
    <div class="partner">
      <img *ngIf="organizer.logoUrl" class="partner__logo" [src]="organizer.logoUrl">
    </div>
  </ng-container>
  <ng-template #organizerName>
    <p *ngIf="organizer" class="title --page">{{organizer.name}}</p>
  </ng-template>

  <div *ngIf="organizerText" class="fs-16 my-3 center" [innerHTML]="organizerText"></div>

  <router-outlet></router-outlet>

</div>
