import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { FAQItem, FaqSection } from '../../../models';
import { debounceTime, delay, first, Subscription, tap } from 'rxjs';
import { FaqSearchService } from '../../../services';
import { HelpStore } from '../../../stores';

interface ExtendedFAQItem extends FAQItem {
  parentName?: string;
}

@Component({
  selector: 'app-faq-content',
  templateUrl: './faq-content.component.html',
  styleUrls: ['./faq-content.component.scss'],
})
export class FaqContentComponent implements OnInit, OnDestroy {
  @Input() set faqSections(faqSections: FaqSection[]) {
    // eslint-disable-next-line no-underscore-dangle
    this._faqSections = faqSections;
    this.faqForm.controls?.filter?.setValue(''); // triggers searchSub on faqSection change in order to reset questions and filter
  }
  get faqSections() {
    // eslint-disable-next-line no-underscore-dangle
    return this._faqSections;
  }
  private _faqSections: FaqSection[];
  @Input() set fragment(fragment: string) {
    if (fragment) {
      this.goToQuestion(fragment);
    }
  }
  /**
  * Unused
  */
  @Input() displayFrequent = false;

  public filteredItems: ExtendedFAQItem[];
  public displayedQuestions = new Array<string>();
  public lastOpenedFragment: string;
  public faqForm = new UntypedFormGroup({});
  public isLoading = true;
  private isBrowser = false;

  private searchSub: Subscription;
  private searchLoadingSub: Subscription;

  constructor(
    public helpStore: HelpStore,
    public faqSearchService: FaqSearchService,
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.searchLoadingSub = this.faqSearchService.isLoading.subscribe( (loading) => {
      if (loading) {
        this.faqForm.disable();
      } else {
        this.isLoading = false;
        this.faqForm.enable();
      }
    });
    this.searchSub = this.faqForm.valueChanges.pipe(
      tap( () => this.isLoading = true),
      debounceTime(300),
      tap(({filter}) => {
        this.helpStore.loadFaqSearch(filter);
        if (!filter && this.displayFrequent) {
          this.filteredItems = this.faqSearchService.getMostSearched(this.faqSections);
        } else {
          this.filteredItems = this.faqSearchService.search(filter, this.faqSections);
        }
        this.filteredItems.forEach((e) => e.parentName = this.faqSearchService.getParentName(e) as string );
        this.filteredItems = this.filteredItems.sort((a,b) =>
          a.parentName < b.parentName
            ? -1
            : a.parentName > b.parentName
              ? 1 :
              b.score - a.score,
        );
        this.isLoading = false;
      }),
    ).subscribe();
  }

  private goToQuestion(fragment) {
    if (this.isBrowser) {
      this.faqSearchService.isLoading.pipe(
        first((loading) => !loading),
        delay(100),
      ).subscribe( () => {
        this.faqSearchService.search(undefined, Object.values(FaqSection)).forEach((item) => {
          if (item.fragment === fragment) {
            this.displayAnswer(item.fragment);
            setTimeout(() => {
              const el = document.getElementById(fragment);
              // new elements may have been loaded above so we adjust scroll
              el?.scrollIntoView({behavior: 'smooth'});
              this.lastOpenedFragment = item.fragment;
            }, 250);
          }
        });
      });
    }
  }

  protected switchAnswerDisplay(answerFragment: string) {
    const matchingIndex = this.displayedQuestions.indexOf(answerFragment);
    if (matchingIndex > -1) {
      this.displayedQuestions.splice(matchingIndex, 1);
      this.lastOpenedFragment = undefined;
    } else {
      this.displayedQuestions.push(answerFragment);
      this.lastOpenedFragment = answerFragment;
    }
  }

  private displayAnswer(answerFragment: string) {
    if (!this.displayedQuestions.includes(answerFragment)) {
      this.displayedQuestions.push(answerFragment);
    }
  }

  ngOnDestroy() {
    this.searchSub?.unsubscribe();
    this.searchLoadingSub?.unsubscribe();
  }

}
