import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Event, Language } from '../../../models';
import { ColorService } from '../../../services';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-event-cover',
  templateUrl: './event-cover.component.html',
  styleUrls: ['./event-cover.component.scss'],
})
export class EventCoverComponent implements OnInit, OnDestroy {
  @Input() set event(event: Event) {
    if (event) {
      // eslint-disable-next-line no-underscore-dangle
      this._event = event;
      this.onEvent();
    }
  }
  public get event() {
    // eslint-disable-next-line no-underscore-dangle
    return this._event;
  }
  private _event: Event;
  private langSub: Subscription;

  public currentLang: Language;

  isLoadingEventInfo = true;
  partnerName: string;

  @ViewChild('backgroundImg', { static: true }) backgroundImg: ElementRef;

  constructor(
    private colorService: ColorService,
    private translocoService: TranslocoService,
  ) {
    this.langSub = this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.currentLang = lang;
    });
  }

  ngOnInit() {
    // on init
    return;
  }

  private onEvent() {
    if (this.event) {
      const tmpPartnerName = this.event.organizerName || this.event.name || ' ';
      // we set uppercase the first letter of organizer name
      this.partnerName = tmpPartnerName[0].toUpperCase() + tmpPartnerName.slice(1);
    }
    this.setBackgroundImage();
    this.setEventColor();
    this.isLoadingEventInfo = false;
  }

  ngOnDestroy() {
    this.colorService.resetEventColor();
    this.langSub?.unsubscribe();
  }

  setBackgroundImage() {
    if (!this.event) {
      return;
    }
    if (this.event && this.event.imgUrl && this.event.imgUrl !== '') {
      this.backgroundImg.nativeElement.style.backgroundImage = 'url(\'' + this.event.imgUrl + '\')';
    } else {
      this.backgroundImg.nativeElement.style.backgroundImage = 'url(\'assets/img/default-event-cover.jpg\')';
    }
  }

  setEventColor() {
    if (this.event && this.event.isPartner && this.event.color && this.event.color !== '') {
      this.colorService.setEventColor(this.event);
    }
  }

}
