<ng-container *transloco="let ttp; read 'ticketPrice'">

  <app-event-custom-message [message]="event?.EventPersonalization?.priceSellingPage"></app-event-custom-message>

  <ng-container *transloco="let tsf; read 'sale.formTitle'">
    <div class="small-container px-0">
      <div class="icon-text mt-4 mb-3">
        <mat-icon class="custom-event">confirmation_number</mat-icon>
        <span class="icon-text__text fw-700 confirmation-page__text">{{ tsf('sellSummary') }}</span>
      </div>

      <mat-card class="custom-border-left my-3 summary__card"> 
        <mat-card-content>
          <p class="summary__card__bold-text">{{ event.name }} </p>
          <p *ngIf="category?.CategoriesGroup?.name" class="summary__card__text">{{ category?.CategoriesGroup?.name }}</p>
          <p class="summary__card__text">{{ category?.name }}</p>
          <p *ngIf="saleTickets?.length > 0" class="summary__card__text">{{ saleTickets.length + ' ' + (saleTickets.length > 1 ? tsf('tickets') : tsf('ticket'))}}</p>
          <p *ngFor="let ticket of saleTickets" class="summary__card__text">
            <ng-container *ngIf="ticket.seatDetails">
              <ng-container *ngIf="saleTickets.length > 1 && hasMatchingProperty(category.displayedSeatingInformation, ticket.seatDetails)">•</ng-container>
              <app-seat-details
                [seatDetails]="ticket.seatDetails"
                [displayedSeatingInformation]="category.displayedSeatingInformation"
              ></app-seat-details>
            </ng-container>
          </p>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="icon-text mt-4 mb-3">
      <mat-icon class="custom-event">sell</mat-icon>
      <span class="icon-text__text fw-700 confirmation-page__text">{{ tsf('pricePerTicket') }}</span>
    </div>
  </ng-container>

  <div class="facing-cards" *ngIf="form && selectedPriceControl && platformPriceControl">

    <div class="facing-cards__card">
      <p class="facing-cards__card__title mb-4">
        <ng-container *ngIf="!isSamePrice">
          {{ ttp('howMuchDoYouWant') }}
        </ng-container>
        <ng-container *ngIf="isSamePrice">
          {{ ttp('whatYouWillReceive') }}
        </ng-container>
      </p>
      <mat-form-field class="facing-cards__card__field" subscriptSizing="dynamic">
        <input
          data-cy="price-input"
          type="number"
          step="0.01"
          matInput
          [formControl]="selectedPriceControl"
          (input)="onSelectedPriceInput($event)"
          (change)="onSelectedPriceChange($event)"
          (onWheel)="$event?.preventDefault()"
        >
        <span matSuffix class="m-1">{{ category?.currencySymbol }}</span>
        <mat-error class="facing-cards__card__field__error">
          {{ ttp('choosePriceBetween', {
            minimalSalePrice: (category?.minimalSalePrice/100 | number: '1.2-2':'fr'),
            maximalSalePrice: (category?.maximalSalePrice/100 | number: '1.2-2':'fr'),
            currencySymbol: category?.currencySymbol
          }) }}
        </mat-error>
      </mat-form-field>
      <div *ngIf="form.controls.selectedPrice.untouched || form.controls.selectedPrice.valid" class="facing-cards__card__after-price fw-400 mt-2">
        <p>{{ ttp('pricePerTicket') }}</p>
        <div class="d-flex --centered --vertical-centered">
          <span>{{ ttp('maxPrice', {
            maximalSalePrice: (category?.maximalSalePrice/100 | number: '1.2-2':'fr'), 
            currencySymbol: category?.currencySymbol 
          }) }}</span>
          <button
            mat-button
            type="button"
            class="button --x-small --outlined-custom-event ml-3"
            [class.--outlined-custom-event]="isMaxPrice()"
            [class.--outlined-primary]="!isMaxPrice()"
            (pointerdown)="onMaxPrice()"
          >
            {{ 'button.max' | transloco }}
          </button>
        </div>
      </div>
      <span class="facing-cards__card__info-details-link">
        <mat-icon class="hidden-icon">confirmation_number</mat-icon>
      </span>
      <p *ngIf="!isSamePrice" class="facing-cards__card__information mt-3">
        {{ ttp('priceRestricted') }}
        <span *ngIf="isPartner">
          {{ ttp('byTheOrganizer') }}
        </span>
        {{ ttp('betweenPrice', {
          minimalSalePrice: (category?.minimalSalePrice/100 | number: '1.2-2':'fr'),
          maximalSalePrice: (category?.maximalSalePrice/100 | number: '1.2-2':'fr'),
          currencySymbol: category?.currencySymbol
        }) }}
      </p>
      <p *ngIf="isSamePrice" class="facing-cards__card__information mt-3">
        {{ ttp('priceRestricted') }}
        <span *ngIf="isPartner">
          {{ ttp('byTheOrganizer') }}
        </span>
        {{ ttp('byTheOrganizer', { price: category?.minimalSalePrice/100 | number: '1.2-2':'fr' }) }}
      </p>

      <div class="facing-cards__card__icon" *appLet="(appService.settings.asObservable() | async) as setting">
        <object
          type="image/svg+xml"
          [data]="'assets/img/illustration/secure-shield.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
          class="facing-cards__card__icon__img"
        >
          <param name="color" [value]="event && event.color ? event.color : '#242425'">  
        </object>
      </div>
    </div>
    
    <div class="facing-cards__card">
      <p class="facing-cards__card__title mb-4">
        {{ ttp('displayedPrice') }}
      </p>
      <mat-form-field class="facing-cards__card__field" subscriptSizing="dynamic">
        <input type="number" step="0.01" matInput [formControl]="platformPriceControl" (input)="onPlatformPriceInput($event)" (change)="onPlatformPriceChange($event)" (onWheel)="$event?.preventDefault()">
        <span matSuffix class="m-1">{{ category?.currencySymbol }}</span>
      </mat-form-field>
      <div class="facing-cards__card__after-price fw-400 mt-2">
        <p>{{ ttp('pricePerTicket') }}</p>
      </div>
      <a class="facing-cards__card__info-details-link" (click)="openDialog()">
        <span>{{ ttp('howIsThisPriceCalculated') }}</span>
        <mat-icon>info</mat-icon>
      </a>
      <p *ngIf="!isSamePrice && minimalPlatformPrice && maximalPlatformPrice" class="facing-cards__card__information">
        <ng-container *ngIf="minimalPlatformPrice !== maximalPlatformPrice; else samePricesOnPlatform">
          {{ ttp('pricesGoesFromTo', {
            minimalPlatformPrice: (minimalPlatformPrice | number: '1.2-2':'fr'),
            maximalPlatformPrice: (maximalPlatformPrice | number: '1.2-2':'fr'),
            currencySymbol: category?.currencySymbol
          }) }}
        </ng-container>
        <ng-template #samePricesOnPlatform>
          {{ ttp('priceIsAt', {
            price: (maximalPlatformPrice | number: '1.2-2':'fr'),
            currencySymbol: category?.currencySymbol
          }) }}
        </ng-template>
      </p>
    </div>

  </div>
</ng-container>
