import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FaqSection } from '../../models';
import { HelpButtonComponent } from '../components/help-button/help-button.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Directive({
  selector: '[appHelp]',
})
export class HelpDirective implements OnInit, OnDestroy {
  @Input('appHelp') section: Array<FaqSection | string>;
  private helpButton: MatDialogRef<HelpButtonComponent, any>;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.addModal();
  }

  addModal() {
    this.helpButton = this.dialog.open(HelpButtonComponent, {
      ariaLabel: 'help dialog',
      closeOnNavigation: false,
      hasBackdrop: false,
      position: {
        bottom: '0px',
        right: '0px',
      },
      panelClass: 'as-help-button',
      data: {
        sections: this.section,
      },
      autoFocus: 'none',
      restoreFocus: false,
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(), // allows to scroll with opened dialog
    });
  }

  ngOnDestroy(): void {
    this.helpButton?.close();
  }

}
