<div class="page-container" *transloco="let tav; read 'accountValidation'">
    
  <app-loader *ngIf="isLoadingVerifying" diameter="40" class="my-5 mx-auto"></app-loader>

  <!-- if user is verified -->
  <div *ngIf="!isLoadingVerifying && isUserVerified">
    <p class="text">
      <ng-container *ngIf="specificProcess === processType.UpdateEmail; else defaultText">
        {{ tav('congratulationsAccountUpdated') }}
      </ng-container>
      <ng-template #defaultText>
        {{ tav('congratulationsAccountVerified') }}
      </ng-template>
      <br><br>
      <ng-container *ngIf="specificProcess === processType.Selling">
        {{ tav('saleNotFinished') }}<br>
        {{ tav('goBackToPreviousTab') }}
      </ng-container>
      <ng-container *ngIf="specificProcess === processType.WaitList">
        {{ tav('waitlistRegistrationNotComplete') }}<br>
        {{ tav('goBackToPreviousTab') }}
      </ng-container>
      <ng-container *ngIf="!specificProcess || specificProcess === processType.UpdateEmail">
        {{ tav('youCanCloseThisTab') }}
      </ng-container>
    </p>

    <div class="illustration" *appLet="(appService.settings.asObservable() | async) as setting">
      <object
        type="image/svg+xml"
        [data]="'assets/img/illustration/happy.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
        class="illustration__img"
      >
        <param name="color" value="url(#reelax-gradient)">  
      </object>
    </div>
  
  </div>

  <!-- if user is not verified  NOT for account locked -->
  <div *ngIf="!isLoadingVerifying && !isUserVerified && validationType !== 'account-unlock' ">
    <div *ngIf="!isRegenerateDisabled">
      <p class="text">
        {{ tav('validationLinkExpired') }}
        <br><br>
        {{ tav('clickToGenerateNewLink') }}
      </p>
      
      <div class="buttons-group --row --centered">
        <button mat-button class="button --primary" (click)="onRegenerateClick()" type="button">{{ tav('generateANewLink') }}</button>
      </div>
    </div>

    <p *ngIf="isRegenerateDisabled && !errors" class="text">
      {{ tav('newLinkSent') }}
      <br><br>
      {{ tav('checkYourEmails') }}
    </p>

    <ng-container *transloco="let tfe; read 'formErrors'">
      <ng-container *ngFor="let error of errors">
        <p [innerHTML]="tfe(error)" class="text"></p>
      </ng-container>
    </ng-container>

  </div>

  <!-- if user has account locked error -->
  <div *ngIf="validationType === 'account-unlock' && !isLoadingVerifying">
    
    <div *ngIf="!isUserVerified">
      <p class="text">
        {{ tav('validationLinkExpired') }}
      </p>
    </div>

    <ng-container *transloco="let tfe; read 'formErrors'">
      <ng-container *ngFor="let error of errors">
        <p [innerHTML]="tfe(error)" class="text"></p>
      </ng-container>
    </ng-container>

  </div>

</div>