import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog-snack.component.html',
  styleUrls: ['./dialog-snack.component.scss'],
})
export class DialogSnackComponent implements OnInit {
  private isBrowser = false;

  constructor(
    public dialogRef: MatDialogRef<DialogSnackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      content: string,
      button: string,
      duration: number,
    },
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser && this.data.duration) {
      setTimeout(() => {
        this.dialogRef.close();
      }, this.data.duration);
    }
  }

}
