import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, AsyncValidator } from '@angular/forms';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { Utils } from '../../../utils';
import { SelectOption } from '../../../../models';

@Component({
  selector: 'app-date-form-field',
  templateUrl: './date-form-field.component.html',
  styleUrls: ['./date-form-field.component.scss'],
})
export class DateFormFieldComponent implements OnInit, OnDestroy {
  public dateOjectFormModel: {day?, month?, year?};
  public dateFormGroup = new FormGroup({});
  @Input() public controlName: string;
  @Input()
  set form(form: FormGroup) {
    if (!form.get(Utils.stringToURLallowed(this.controlName))) {
      form.addControl(Utils.stringToURLallowed(this.controlName), this.dateFormGroup, {emitEvent: false});
      this.init = true;
    }
  }
  @Input() validators: string[] = [];
  @Input() asyncValidators?: AsyncValidator[] = [];
  @Input() debug = false;
  @Input() options: {label}; // Partial<FormOptions>;
  @Input() disabled: boolean;
  @Input()
  set formModel(formModel) {
    // eslint-disable-next-line no-underscore-dangle
    this._formModel = formModel;
    if (formModel[this.controlName]) {
      const formModelDate = new Date(formModel[this.controlName]);
      this.dateOjectFormModel = {
        day: formModelDate.getUTCDate(),
        month: formModelDate.getUTCMonth(),
        year: formModelDate.getUTCFullYear(),
      };
    } else {
      this.dateOjectFormModel = {};
    }

  }
  get formModel(){
    // eslint-disable-next-line no-underscore-dangle
    return this._formModel;
  }
  private _formModel;

  public init = false;

  private formSub: Subscription;
  private translationSub: Subscription;

  // tcm(month) calendar.months
  private months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  public availableDays: SelectOption[];
  public availableMonths: SelectOption[];
  public availableYears = [...Array(80).keys()]
    .map( (n) => (new Date().getFullYear()) - n)
    .map( (m) => ({value: m, viewValue: m.toString()}));

  constructor(
    private translocoService: TranslocoService,
  ) {
  }

  ngOnInit(): void {
    this.updateAvailableDays(0);
    this.formSub = this.dateFormGroup.valueChanges.pipe(
      distinctUntilChanged( (
        p: typeof this.dateOjectFormModel,
        c: typeof this.dateOjectFormModel,
      ) => p.month === c.month && p.year === c.year && p.day === c.day),
    ).subscribe({
      next: (dateValue: typeof this.dateOjectFormModel) => {
        this.updateAvailableDays(dateValue.month, dateValue.year);
        if (
          Number.isInteger(dateValue.year) &&
          Number.isInteger(dateValue.month) &&
          Number.isInteger(dateValue.day)
        ) {
          this.formModel[this.controlName] = Date.UTC(
            dateValue.year,
            dateValue.month,
            dateValue.day,
          );
        }
      },
    });
    this.translationSub = this.translocoService.selectTranslateObject('calendar.months')
      .subscribe( (months) => {
        this.availableMonths = this.months.map( (m, i) => ({value: i, viewValue: months[m]}));
      });
  }

  public isRequired() {
    return this.validators.includes('required');
  }

  updateAvailableDays(month, year?) {
    /* If month is unset, january, march, may, july, august, october, december : 31 days displayed */
    if (!month || [0, 1, 3, 5, 7, 8, 10, 12].includes(month)) {
      // produces array with value from 0 to 31 and then remove the 0
      this.availableDays = [...Array(32).keys()].slice(1).map( (m) => ({value: m, viewValue: m.toString()}));
    } else if ([4, 6, 9, 11].includes(month)) {
      /* If month is april, june, september, november : 30 days displayed */
      this.availableDays = [...Array(31).keys()].slice(1).map( (m) => ({value: m, viewValue: m.toString()}));
    } else if (month === 2) {
      /* If month is february, either 28 or 29 days */
      /* If year undefined or leap : 29 days displayed for february */
      if (!year || year % 4 === 0) {
        this.availableDays = [...Array(30).keys()].slice(1).map( (m) => ({value: m, viewValue: m.toString()}));
      } else {
        /* If year is not leap : 28 days displayed for february */
        this.availableDays = [...Array(29).keys()].slice(1).map( (m) => ({value: m, viewValue: m.toString()}));
      }
    }
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
    this.translationSub?.unsubscribe();
  }

}
