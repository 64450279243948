<ng-container *transloco="let t; read: 'help'">
  <h2 class="faq-title mb-3" *ngIf="!displayFrequent">
    {{ t('youAre') }}
    <ng-container *ngFor="let s of faqSections; last as isLast">{{ t(s) | uppercase }} {{isLast?'':', '}}</ng-container>
  </h2>

  <form [formGroup]="faqForm" class="form">
    <app-text-form-field
      class="form__field --large"
      [form]="faqForm"
      [controlName]="'filter'"
      [formModel]="{filter: helpStore.getFaqSearch()}"
      [options]="{
        label: 'help.howCanWeHelp',
        iconName: 'search'
      }"
    >
    </app-text-form-field>
  </form>

  <app-loader *ngIf="isLoading" diameter="40" class="m-auto"></app-loader>
  
  <p *ngIf="filteredItems?.length <= 0 && displayFrequent" class="fs-16 italic">
    {{ t('nothingFound') }}
  </p>
  
  <p *ngIf="displayFrequent && helpStore.getFaqSearch()?.length <= 0" class="fs-16 italic mt-2">
    {{ t('FAQ') }}
  </p>
  
  <div
    class="faq-item"
    [ngClass]="{'--last-opened': lastOpenedFragment === faqItem.fragment}"
    *ngFor="let faqItem of filteredItems; let index = index"
    [appTrack]="{category: 'faq', action: 'open-question', name: faqItem.fragment, value: displayedQuestions.includes(faqItem.fragment) ? 0 : 1}"
  >
    <h3 class="faq-title mt-5 mb-4" *ngIf="!filteredItems[index-1] || faqItem.parentName !== filteredItems[index-1].parentName">{{faqItem.parentName}}</h3>
    <button class="faq-item__question" (click)="switchAnswerDisplay(faqItem.fragment)" [id]="faqItem.fragment">
      <mat-icon class="mt-1">
        {{displayedQuestions.includes(faqItem.fragment) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}
      </mat-icon>
      <span [innerHTML]="faqItem.question"></span>
    </button>
    <div class="faq-item__answer" [ngClass]="displayedQuestions.includes(faqItem.fragment)? '--opened' : '--hidden'">
      <div [innerHTML]="faqItem.answer"></div>
    </div>
  </div>
  
  <p *ngIf="displayFrequent && helpStore.getFaqSearch()?.length <= 0" class="fs-16 italic my-5">
    ... {{ t('andManyOtherAnswers', { questionsNb: faqSearchService?.anwsersNb - filteredItems?.length}) }}
  </p>

</ng-container>
