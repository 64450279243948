import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TimeUnit } from '../../../../models';
import { FormField } from '../../../../shared/components/inputs/form';

@Component({
  selector: 'app-date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.scss'],
})
export class DateRangeSelectComponent extends FormField<{Start: Date, End: Date}> implements OnInit, OnDestroy {
  public maxDate: Date;
  public disableWeeks: boolean;
  public disableMonths: boolean;

  predefinedPeriodOptions: Array<{label: string, value: number, type: TimeUnit}> = [
    { label: 'today', type: 'day', value: 0 },
    { label: 'yesterdayAndToday', type: 'day', value: 1 },
    { label: '7days', type: 'day', value: 7 },
    { label: '14days', type: 'day', value: 14 },
    { label: '4weeks', type: 'week', value: 4 },
    { label: '8weeks', type: 'week', value: 8 },
    { label: '3months', type: 'month', value: 3 },
    { label: '6months', type: 'month', value: 6 },
    { label: '12months', type: 'month', value: 12 },
    { label: 'beginning', type: 'month', value: 99 },
  ];

  public controlStart = new UntypedFormControl();
  public controlEnd = new UntypedFormControl();

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.maxDate = new Date();
    if (this.form) {
      this.form.addControl(
        // todo fix date
        this.controlName + 'Start',
        this.controlStart,
        {emitEvent: false},
      );
      this.form.addControl(
        this.controlName + 'End',
        this.controlEnd,
        {emitEvent: false},
      );
      this.form.get(this.controlName).setValue(2);
    }
  }

  setFormModel(m) {
    super.setFormModel(m);
    this.controlStart.setValue(m[this.controlName + 'Start'], {emitEvent: false});
    this.controlEnd.setValue(m[this.controlName + 'End'], {emitEvent: false});
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log(`[${this.controlName}Start]: value - formModel`, this.controlStart.value, this.formModel);
      // eslint-disable-next-line no-console
      console.log(`[${this.controlName}End]: value - formModel`, this.controlEnd.value, this.formModel);
    }
    this.setPredefinedPeriod(2);
  }

  setPredefinedPeriod(index: number) {
    const period = this.predefinedPeriodOptions[index];
    if (period) {
      let referenceDate: Date;
      const today = new Date();

      referenceDate = this.formModel[this.controlName + 'End'] ?? today;
      referenceDate = new Date(referenceDate.setHours(0, 0, 0, 0));
      const fromDate = new Date(referenceDate.getTime());
      if (period.type === 'month') {
        fromDate.setMonth(fromDate.getMonth() - period.value);
      } else if (period.type === 'day') {
        fromDate.setDate(fromDate.getDate() - period.value);
      } else if (period.type === 'week') {
        fromDate.setDate(fromDate.getDate() - (7 * period.value));
      } else {
        throw new Error('not a valid period');
      }

      this.controlStart.setValue(fromDate);
      this.controlEnd.setValue(referenceDate);
    }
  }

  cleanPeriod() {
    this.form.get(this.controlName).setValue('');
  }

}
