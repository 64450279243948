<button
  type="button"
  mat-button
  [class]="cssClass"
  (click)="onClick($event)"
>
  <ng-container [ngSwitch]="status">
    <ng-container
      *ngSwitchCase="'indeterminate'"
    >
      <mat-icon class="button__icon">indeterminate_check_box</mat-icon>
    </ng-container>
    <ng-container
      *ngSwitchCase="true"
    >
      <mat-icon class="button__icon">check_box</mat-icon>
    </ng-container>
    <ng-container
      *ngSwitchCase="false"
    >
      <mat-icon class="button__icon">check_box_outline_blank</mat-icon>
    </ng-container>
  </ng-container>
  {{ options.label }}
</button>
