<ng-container *appLet="(appService.settings.asObservable() | async) as setting">
  <div class="px-3 small-container" [ngClass]="{'bg-confetti-animated': isBirthday}" *transloco="let tav; read 'accountValidation'">
    <h1 class="subtitle --center mt-5">
      <ng-container *ngIf="(userRole && userRole !== 'anonymous'); then logged; else notLogged"></ng-container>
      <ng-template #logged>
        {{ tav(isBirthday ? 'happyBirthday' : 'hello', { firstname }) }}
      </ng-template>
      <ng-template #notLogged>
        <ng-container *ngIf="isLogin; then login; else registration"></ng-container>
        <ng-template #login>
          {{ isPasswordForgotten? tav('resetYourPassword') : tav('login')}}
        </ng-template>
        <ng-template #registration>
          {{ tav('registration') }}
        </ng-template>
      </ng-template>
    </h1>

    <mat-card class="mt-4 mb-5">
      <div class="center">
        <!-- classic login form -->
        <mat-card-content>
          <ng-container *ngIf="isLogin && !isNewPasswordMailSent && (!userRole || userRole === 'anonymous')">
            <form [formGroup]="credentialsForm" (ngSubmit)="onSubmitLogin()">
    
              <div class="form --centered">
                <mat-form-field class="form__field mb-4" subscriptSizing="dynamic">
                  <mat-label>{{tav('email')}}</mat-label>
                  <input [appTrim]="true" formControlName="email" name="email" matInput type="email" email required>
                  <mat-hint *ngIf="!isPasswordForgotten">{{ tav('weWillSendYourTicketInfoHere') }}</mat-hint>
                  <mat-hint *ngIf="isPasswordForgotten">{{ tav('pleaseEnterTheAddressYouProvided') }}</mat-hint>
                  <mat-error *ngIf="getError(credentialsForm, 'email')">{{ 'formErrors.' + getError(credentialsForm, 'email') | transloco }}</mat-error>
                </mat-form-field>
    
                <mat-form-field *ngIf="!isPasswordForgotten" class="form__field mb-4">
                  <mat-label>{{tav('password')}}</mat-label>
                  <input formControlName="password" name="password" matInput [type]="hidePassword1 ? 'password' : 'text'" required>
                  <button type="button" mat-icon-button matSuffix (click)="hidePassword1 = !hidePassword1">
                    <mat-icon>{{hidePassword1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="getError(credentialsForm, 'password')">{{ 'formErrors.' + getError(credentialsForm, 'password') | transloco }}</mat-error>
                </mat-form-field>
              </div>
    
              <app-loader *ngIf="isLoadingAuth" diameter="40" class="m-auto"></app-loader>
    
              <p *ngIf="!!formError && ((getError(credentialsForm, 'password') && !isPasswordForgotten) || getError(credentialsForm, 'email'))" class="error fs-16 center">{{ 'formErrors.' + formError | transloco }}</p>
              <p *ngIf="!((getError(credentialsForm, 'password') && !isPasswordForgotten) || getError(credentialsForm, 'email')) && serverErrors?.length > 0" class="error fs-16 center">
                <ng-container *ngFor="let error of serverErrors; let last = last">
                  {{ 'formErrors.' + error | transloco }}
                  <br *ngIf="!last">
                </ng-container>
              </p>
    
              <div class="buttons-group --row --centered">
                <button mat-button class="button --primary --full-width --small" type="submit" [disabled]="isLoadingAuth">
                  {{ (isPasswordForgotten? 'button.confirm' : 'button.logIn' ) | transloco}}
                </button>
              </div>
    
              <a class="mx-auto pt-4 fs-14 link --primary" (click)="onForgetPasswordClick()">{{isPasswordForgotten? tav('backToTheLoginForm') : tav('forgotYourPassword')}}</a>
    
              <div class="py-4">
                <mat-divider class="m-0" [inset]="true"></mat-divider>
              </div>
    
              <p *ngIf="!isPasswordForgotten" class="fs-16">{{ tav('dontHaveAnAccount') }}</p>
              <div class="buttons-group --row --centered">
                <button mat-button class="button --outlined-primary --full-width --small" (click)="switchLogin()" type="button" [disabled]="isLoadingAuth">
                  {{ tav('signUp') }}
                </button>
              </div>
            </form>
          </ng-container>
    
          <!-- registration form if user not logged in or if user is anonymous -->
          <ng-container *ngIf="!isLogin && (!userRole || userRole === 'anonymous')">
  
            <p *ngIf="setting.disabledEmailVerification" class="text">
              <mat-icon [style.verticalAlign]="'bottom'">warning</mat-icon>
              {{ tav('checkEmailSpelling') }}
            </p>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmitRegister()">
  
              <div class="form --centered">
                <mat-form-field class="form__field mb-4" subscriptSizing="dynamic">
                  <mat-label>{{tav('email')}}</mat-label>
                  <input [appTrim]="true" formControlName="email" matInput type="email" email required>
                  <mat-hint>{{ tav('weWillSendYourTicketInfoHere') }}</mat-hint>
                  <mat-error *ngIf="getError(registerForm, 'email')">{{ 'formErrors.' + getError(registerForm, 'email') | transloco }}</mat-error>
                </mat-form-field>

                <mat-form-field class="form__field mb-2">
                  <mat-label>{{tav('password')}}</mat-label>
                  <input formControlName="password" matInput [type]="hidePassword2 ? 'password' : 'text'" (input)="checkPasswordMatch()" required>
                  <button type="button" mat-icon-button matSuffix (click)="hidePassword2 = !hidePassword2">
                    <mat-icon>{{hidePassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="getError(registerForm, 'password')">{{ 'formErrors.' + getError(registerForm, 'password') | transloco }}</mat-error>
                </mat-form-field>

                <mat-form-field class="form__field mb-2">
                  <mat-label>{{tav('passwordConfirmation')}}</mat-label>
                  <input formControlName="passwordConfirm" matInput  [type]="hideConfirmPassword ? 'password' : 'text'" (input)="checkPasswordMatch()" required>
                  <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
                    <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="getError(registerForm, 'passwordConfirm')">{{ 'formErrors.' + getError(registerForm, 'passwordConfirm') | transloco }}</mat-error>
                </mat-form-field>

                <app-password-strength class="full-width text-left mb-3" [form]="registerForm"></app-password-strength>
    
                <div class="form__field mb-4" *transloco="let tai; read 'accountInfos'">
                  <mat-checkbox name="cgReelax" formControlName="cgReelax" color="primary" required><p class="checkbox-text">{{ tai('iAcceptThe') }} <a class="link --primary" href="https://reelax-tickets.com/assets/pdf/CGU_reelax.pdf" target="_blank">{{ tai('termsAndConditions') }}</a> {{ tai('andThe') }} <a class="link --primary" href="https://reelax-tickets.com/assets/pdf/PP_reelax.pdf" target="_blank">{{ tai('privacyPolicy') }}</a> {{ tai('ofReelaxTickets') }}.<span [matTooltip]="'formErrors.required' | transloco" class="custom-event">&nbsp;*</span></p></mat-checkbox>
                  <mat-error class="fs-12" *ngIf="getError(registerForm, 'cgReelax')">{{ 'formErrors.' + getError(registerForm, 'cgReelax') | transloco }}</mat-error>
                </div>
              </div>
    
              <app-loader *ngIf="isLoadingAuth" diameter="40" class="m-auto"></app-loader>

              <p *ngIf="!!formError && (!registerForm.valid || passwordStrengthComponent?.progressSecurisation < 80)" class="error fs-16 center">{{ 'formErrors.' + formError | transloco }}</p>
              <p *ngIf="registerForm.valid && serverErrors?.length > 0" class="error fs-16 center">
                <ng-container *ngFor="let error of serverErrors; let last = last">
                  {{ 'formErrors.' + error | transloco }}
                  <br *ngIf="!last">
                </ng-container>
              </p>

              <div class="buttons-group --row --centered">
                <button mat-button class="button --small --primary --full-width" type="submit" [disabled]="isLoadingAuth">
                  {{ 'button.signUp' | transloco }}
                </button>
              </div>
    
              <div class="py-4">
                <mat-divider class="m-0" [inset]="true"></mat-divider>
              </div>
    
              <p class="fs-16">{{ tav('alreadyHaveAnAccount') }}</p>
              <div class="buttons-group --row --centered">
                <button mat-button class="button --small --outlined-primary --full-width" (click)="switchLogin()" type="button">
                  {{ tav('loginYourself') }}
                </button>
              </div>
            </form>
          </ng-container>
    
          <!-- if user is logged in and not anonymous -->
          <ng-container *ngIf="userRole && userRole !== 'anonymous'">
            <p class="fs-16 py-5">
              {{ tav('youAreWellConnected') }}
            </p>
            <a *ngIf="hasHistory" class="link --primary mb-5 fs-12" (click)="goBack()">
              {{ tav('goBackWhereILeftOff') }}
            </a>
            <div class="buttons-group --row --centered"><!-- todo -->
              <button *ngIf="!setting?.isWidget" mat-button class="button --outlined-primary --small mb-3" routerLink="/evenements">
                {{ 'button.events' | transloco }}
              </button>
              <button mat-button class="button --primary --small mb-3" routerLink="/mon-compte">
                <mat-icon class="button__icon">account_circle</mat-icon> {{ 'button.myAccount' | transloco }}
              </button>
              <button *ngIf="userRole && userRole === 'organizer'" routerLink="/orga"
                mat-button class="button --primary --small mb-3">
                <mat-icon class="button__icon">insert_chart</mat-icon> {{ 'button.myDashboard' | transloco }}
              </button>
              <button *ngIf="userRole && userRole === 'admin'" routerLink="/admin"
                mat-button class="button --primary --small mb-3">
                <mat-icon class="button__icon">dashboard</mat-icon> {{ 'button.myDashboard' | transloco }}
              </button>
            </div>
          </ng-container>
    
          <!-- new password email sent -->
          <ng-container *ngIf="isNewPasswordMailSent">
            <p class="fs-16 pb-5">
              {{ tav('ifAnAccountMatches') }}<span *ngIf="email"> ({{email}})</span>, {{ tav('youWillReceiveAnEmail') }}<br>
              {{ tav('pleaseClickOnTheLink') }}
            </p>
            <p class="fs-16 pb-5">
              {{ tav('contactUsIfProblem') }} <a class="link --primary" routerLink="/contact">{{ tav('byClickingHere') }}</a>
            </p>
            <a class="link --primary fs-16" (click)="goBackToLogin()">
              {{ tav('backToTheLoginPage') }}
            </a>
          </ng-container>
        </mat-card-content>
        
      </div>
    </mat-card>
  
    <!-- information on login or registration page -->
    <ng-container *ngIf="(userRole === 'anonymous') && !isPasswordForgotten && !isNewPasswordMailSent && !this.specificProcess">
      <h2 class="d-flex center"><mat-icon class="mr-3 custom-event">person</mat-icon> {{ tav('reelaxTicketsAccount') }}</h2>
      <mat-card ngClass="mt-4 custom-border-left center fs-16 mb-5">
        <mat-card-content>
          <p class="fs-16 pb-5">
            {{ tav('youCanBuyWithoutAnyAccount') }}<br>
            {{ tav('createAnAccountToSellOrGoFaster') }}
          </p>
          <ng-container *ngIf="hasHistory">
            <a *ngIf="hasHistory" class="link --primary" (click)="goBack()">
              {{ tav('goBackWhereILeftOff') }}
            </a>
            <br>
          </ng-container>
          <a *ngIf="!environment.production" class="link --danger" (click)="logout()">
            {{ 'button.logOut' | transloco }}
          </a>
          <div *ngIf="!hasHistory && !setting?.isWidget" class="buttons-group --row --centered">
            <a routerLink="/evenements">
              <button mat-button class="button --outlined-primary">
                {{ 'button.seeAvailableEvents' | transloco }}
              </button>
            </a>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
  
  </div>
  
</ng-container>
