<div class="content">

  <div *ngIf="data.content" mat-dialog-content>

    {{ data.content }}

  </div>

  <mat-dialog-actions>
    <button *ngIf="data.button" mat-button [mat-dialog-close]="{button: data.button}" class="snack-button">
      {{ data.button }}
    </button>
  </mat-dialog-actions>
</div>