<div class="dialog">

  <div mat-dialog-content class="dialog-content" *ngIf="data.message">

    <div class="dialog-content__text mb-2" [innerText]="data.message"></div>

  </div>

  <mat-dialog-actions>
    <button *ngIf="data.button" mat-button [mat-dialog-close]="{button: data.button}" class="ml-auto button --small --primary">
      {{ data.button }}
    </button>
  </mat-dialog-actions>

</div>