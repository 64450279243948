import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { ColorService } from '../../../services';
import { Language, LanguageMap } from '../../../models';
import { Utils } from '../../utils';

@Component({
  selector: 'app-event-custom-message',
  templateUrl: './event-custom-message.component.html',
  styleUrls: ['./event-custom-message.component.scss'],
})
export class EventCustomMessageComponent implements AfterViewInit {
  @Input() public message?: LanguageMap;
  @Input() public translocoMessage?: string;
  // MatCard has not nativeElement so you have to fetch element reference
  @ViewChild('messageCard', { read: ElementRef }) messageCard: ElementRef;
  private isBrowser = false;
  public Language = Language;
  public languages = Object.values(this.Language);

  constructor(
    private colorService: ColorService,
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  hasOneTanslation() {
    return Utils.hasOneTanslation(this.message, this.Language);
  }

  ngAfterViewInit() {
    const backgroundColor = this.colorService.getEventColor(true);
    if (this.isBrowser && (this.hasOneTanslation() || this.translocoMessage)) {
      this.messageCard.nativeElement.style.backgroundColor = backgroundColor;
    }
  }

}
