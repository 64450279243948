<mat-radio-group
  class="form__field"
  [formControl]="control"
  [required]="validators.includes('required')"
  [value]="formModel[this.controlName]"
>
  <mat-radio-button
    *ngFor="let option of radioOptions"
    class="mr-4"
    [disabled]="option.disabled"
    [value]="option.value"
  >
    {{option.viewValue}}
  </mat-radio-button>
</mat-radio-group>
