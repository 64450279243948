import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';
import { GroupSelectOption, SelectOption } from '../../../../models';

@Component({
  selector: 'app-select-form-field',
  templateUrl: './select-form-field.component.html',
  styleUrls: ['./select-form-field.component.scss'],
})
export class SelectFormFieldComponent extends FormField<SelectOption> implements OnInit, OnDestroy {
  @Input() public selections: SelectOption[] | GroupSelectOption[];

  constructor() {
    super();
  }

  public getSelection(value: string | number) {
    if(!value && value !== 0){
      return undefined;
    }
    // case group of list of options
    if (this.selections?.[0].hasOwnProperty('values')) {
      for (const group of this.selections) {
        for (const option of (group as {name: string; values: SelectOption[]}).values ) {
          if (option.value === value) {
            return option as SelectOption;
          }
        }
      }
    }
    // case list of options
    if (this.selections?.[0].hasOwnProperty('value')) {
      for (const option of this.selections as SelectOption[]) {
        if (option.value === value) {
          return option as SelectOption;
        }
      }
    }
    return undefined;
  }

}
