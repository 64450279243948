import { Component, Input } from '@angular/core';
import { SEOService } from '../../../services';
import { Event, Ticket } from '../../../models';

@Component({
  selector: 'app-social-media-preview',
  templateUrl: './social-media-preview.component.html',
  styleUrls: ['./social-media-preview.component.scss'],
})
export class SocialMediaPreviewComponent {
  @Input() event: Event;
  @Input() ticket: Partial<Ticket>;
  @Input() type: 'sell' | 'buy';

  constructor(
    public seoService: SEOService,
  ) { }

}
