import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControlOptions } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { SelectOption } from '../../../../models';
import { FormField } from '../form';


@Component({
  selector: 'app-autocomplete-form-field',
  templateUrl: './autocomplete-form-field.component.html',
  styleUrls: ['./autocomplete-form-field.component.scss'],
})
export class AutocompleteFormFieldComponent extends FormField<SelectOption> implements OnInit, OnDestroy {
  @Input() public selections: {[id: string | number]: SelectOption};
  public filteredSelections: Observable<SelectOption[]>;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.filteredSelections = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => value ? Object.values(this.selections)
        .filter((s) => s.viewValue.toLowerCase().includes(
          value.hasOwnProperty('toLowerCase') || typeof value === 'string' ? value.toLowerCase() : value,
        )) : Object.values(this.selections)),
    );
  }

  displayFn(pick: SelectOption | number | string) {
    if (!pick) {
      return '';
    }
    if (typeof pick === 'string') {
      return pick;
    }
    if (typeof pick === 'number') {
      return pick.toString();
    }
    return pick.viewValue;
  }

  getFormControlOptions(): FormControlOptions {
    return {updateOn: 'change'};
  }

  /*
  TODO use display function to avoid saving whole object
  https://github.com/angular/components/issues/4863

  displayFn(filteredSelections): (pickId: string | number) => string {
    return (pickId: string | number) => {
      if (!pickId || !!filteredSelections) {
        return '';
      }
      console.log(new Date().getTime())
      // const picked = this.selections.find(option => option.value === pickId);
      const picked = this.selections[pickId];
      return picked ? picked.viewValue : 'error';
    };
  }
  */
}
