import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, RouteConfigLoadStart, RouteConfigLoadEnd, Router, ActivatedRoute } from '@angular/router';
import { captureException } from '@sentry/angular-ivy';
import { Subscription, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RouterService, SEOService, NotificationService, AppService, ScrollService } from '../../services';
import { CookieStore } from '../../stores/cookie.store';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit, OnDestroy {

  title = 'tickexchange';

  private isBrowser = false;
  public isWidget = false;
  private routerSubscription: Subscription;
  private settingSub: Subscription;

  @ViewChild('header') public header: ElementRef;

  loadingRouteModule = false;

  constructor(
    private router: Router,
    // need to import routerService here so it loads routes history available for every component
    private routerService: RouterService,
    private seoService: SEOService,
    private notificationService: NotificationService,
    private cookieStore: CookieStore,
    private route: ActivatedRoute,
    public appService: AppService,
    private platform: Platform,
    private scrollService: ScrollService,
  ) {
    this.isBrowser = this.platform.isBrowser;
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.isBrowser) {
        this.scrollTop(event.urlAfterRedirects);
        // we dismiss notifications on route change
        this.notificationService.dismiss();
        // after a navigation, if we have a referer we add it to the query param
        // required to save it in case of page reload
        try {
          const url = new URL(window.location.origin + event.urlAfterRedirects);
          if (this.appService.settings.value?.referer && !url.searchParams.has('wref')) {
            this.router.navigate(
              [],
              {
                relativeTo: this.route,
                queryParams: { wref: this.appService.settings.value?.referer },
                queryParamsHandling: 'merge',
                replaceUrl: true,
              },
            );
          }
        } catch (error) {
          captureException(error);
        }
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteModule = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteModule = false;
      }
    });
    this.routerService.getCurrentUrl();
    // SEO
    const translations = this.route.snapshot.data?.SEOTranslations ?
      this.route.snapshot.data?.SEOTranslations : this.seoService.defaultSEOTranslations;
    this.seoService.updateTitle(translations.title);
    this.seoService.updateUrl(environment.domain);
    this.seoService.updateDescription(translations.description);
    this.seoService.updateImage(null, 'Reelax Tickets', '#242425');
    // browser display
    if (this.isBrowser) {
      this.settingSub = this.appService.settings.pipe(
        tap( (settings) => {
          this.isWidget = settings.isWidget;
          // cookie
          this.cookieStore.getCookie().then( (displayed) => {
            if (!displayed && !this.isWidget) {
              this.notificationService.noCookie();
              this.cookieStore.setCookie(true);
            }
          });
        }),
      ).subscribe();
    }
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
    this.settingSub?.unsubscribe();
  }

  scrollTop(route) {
    const noScrollResetRoutes = ['achat', 'purchase', 'vente', 'sale', 'pro#partenaires', 'search=', 'admin/explorateur-de-billets', 'admin/explorateur-d-evenements'];
    if (!noScrollResetRoutes.some((noScrollRoute) => route.indexOf(noScrollRoute) > -1)) {
      this.scrollService.scrollTop(this.header?.nativeElement as HTMLElement);
    }
  }

}
