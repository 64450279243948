import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-feedbacks-carousel-vertical',
  templateUrl: './feedbacks-carousel-vertical.component.html',
  styleUrls: ['./feedbacks-carousel-vertical.component.scss'],
})
export class FeedbacksCarouselVerticalComponent {
  environment = environment;
  @Input() feedbacks: any[] = new Array<any>();
  @Input() reverse = false;
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) protected platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

}
