<ng-container *transloco="let tc; read 'checkSeller'">

  <h2 class="subtitle --center my-4">
    <mat-icon class="subtitle__icon">badge</mat-icon>
    {{ tc('identityDocument') }}
  </h2>

  <div class="id-container mb-5">

    <app-loader *ngIf="isLoadingUserKyc" diameter="40" class="my-3 mx-auto"></app-loader>

    <ng-container *ngIf="!isLoadingUserKyc && !isLoadingUserBankingInfo">

      <!-- if kyc already submitted but not yet validated -->
      <ng-container *ngIf="userBanking && (userBanking.mangoKYCLevel === KYCLevel.CREATED)">
        <mat-card>
          <mat-card-content class="id-container__message">
            <mat-icon class="orange">cached</mat-icon>
            <span class="id-container__message__text">
              {{ tc('IDBeingValidated') }}
              <br>
              <br>
              {{ tc('youCanContinueSellingTickets') }}
            </span>
          </mat-card-content>
        </mat-card>
      </ng-container>

      <!-- if kyc already submitted but refused -->
      <ng-container *ngIf="userBanking?.mangoKYCLevel === 'REFUSED'">
        <mat-card class="mb-3">
          <mat-card-content class="id-container__message">
            <mat-icon class="red">close</mat-icon>
            <span class="id-container__message__text">
              {{ tc('IDNotValidatedSendAnother') }}
            </span>
          </mat-card-content>
        </mat-card>
        <p class="error fs-16" *ngIf="userKyc.kycRefusedReasonType">
          {{ tc('kycErrorType.refusedReason') }} {{ tc('kycErrorType.' + userKyc.kycRefusedReasonType) }}
        </p>
        <p class="error fs-16" *ngIf="userKyc.kycRefusedReasonMessage">
          {{userKyc.kycRefusedReasonMessage}}
        </p>
        <p class="error fs-16" *ngIf="userKyc.kycRefusedFlags?.length > 0">
          {{ tc('moreInformation') }} : 
          <ng-container *ngFor="let errMsg of userKyc.kycRefusedFlags" >
            {{ tc('kycErrorFlags.' + errMsg) }}
          </ng-container>
        </p>
      </ng-container>

      <!-- if kyc validated -->
      <ng-container *ngIf="userBanking?.mangoKYCLevel === KYCLevel.STRONG">
        <mat-card>
          <mat-card-content class="id-container__message">
            <mat-icon class="green">check</mat-icon>
            <span class="id-container__message__text">
              {{ tc('IDValidatedWeCanPayYou') }}
            </span>
          </mat-card-content>
        </mat-card>
      </ng-container>

      <!-- if kyc not validated and no kyc currently being reviewed -->
      <ng-container *ngIf="!userBanking?.mangoKYCId && ([KYCLevel.REFUSED, KYCLevel.LIGHT].includes(userBanking?.mangoKYCLevel) || (userBanking?.mangoKYCLevel === KYCLevel.CREATED && user.UserBankingInfo?.updatedAt < twoHoursAgo))">
        <p class="id-container__explanation">
          {{ tc('chooseTheIDDocument') }}
        </p>

        <form [formGroup]="kycTypeForm">
          <mat-radio-group formControlName="choosenKYCDocumentType" class="column-radios">
            <mat-radio-button *ngFor="let docType of KYCDocumentTypeInfos | keyvalue" type="radio"
              class="mb-3" [value]="docType.value.identifier">
              <span class="fs-16">{{ tc(docType.value.identifier) }}</span>
            </mat-radio-button>
          </mat-radio-group>
        </form>

        <ng-container *ngIf="kycTypeForm.value.choosenKYCDocumentType">
          <img [src]="'assets/img/' + KYCDocumentTypeInfos[kycTypeForm?.value?.choosenKYCDocumentType].imgUrl">

          <app-uploader acceptedFiles=".jpeg,.jpg,.png"></app-uploader>

          <form [formGroup]="checkboxesForm" class="id-checkboxes">
            <p class="id-checkboxes__phrase">
              {{ tc('iCertifyIAttachedADocument') }}
            </p>

            <div class="id-checkboxes__boxes">
              <mat-checkbox name="readable" formControlName="readable" color="accent" required>
                <p class="checkbox-text">
                  <span>{{ tc('readableColorDirection') }}</span>
                  <span *ngIf="KYCDocumentTypeInfos[kycTypeForm?.value?.choosenKYCDocumentType]?.isRectoVerso">,
                    {{ tc('withFrontAndBack') }}
                  </span>
                  <mat-error *ngIf="checkboxesForm.controls['readable'].touched && !checkboxesForm.controls['readable'].valid" class="fs-12">
                    {{ tc('readableError') }}
                  </mat-error>
                </p>
              </mat-checkbox>
              <mat-checkbox name="valid" formControlName="valid" color="accent" required>
                <p class="checkbox-text">{{ tc('notExpiredNotFalsified') }}</p>
                <mat-error *ngIf="checkboxesForm.controls['valid'].touched && !checkboxesForm.controls['valid'].valid" class="fs-12">
                  {{ tc('expiredError') }}
                </mat-error>
              </mat-checkbox>
              <mat-checkbox name="owner" formControlName="owner" color="accent" required>
                <p class="checkbox-text">{{ tc('containingTheFollowingInformation') }}</p>
              </mat-checkbox>
              
              <div class="owner-info">
                <p>{{ tc('firstname') }} <span class="fw-600">{{user?.firstname | titlecase}}</span></p>
                <p>{{ tc('birthname') }} <span class="fw-600">{{user?.lastname | uppercase}}</span></p>
                <p>{{ tc('dateOfBirth') }} <span class="fw-600">{{user?.birthday | date: 'longDate':'+0000':('language.locale' | transloco) }}</span></p>
                <p>{{ tc('toEdit') }}
                  <a class="link --primary" target="_blank" href="/mon-compte/informations-personnelles">{{ tc('clickHere') }}</a>.
                </p>
                <p>{{ tc('doNotRefresh') }}</p>
                <mat-error *ngIf="checkboxesForm.controls['owner'].touched && !checkboxesForm.controls['owner'].valid" class="fs-12">
                  {{ tc('ownerDataMatchError') }}
                </mat-error>
              </div>

            </div>
          </form>
        </ng-container>

        <div class="dialog-link">
          <p class="dialog-link__text">{{ tc('whyDoYouNeedMyID') }}</p>
          <mat-icon class="dialog-link__icon" [matTooltip]="tc('moreInformation')" (click)="openDialog()">info</mat-icon>
        </div>

      </ng-container>
    </ng-container>

  </div>

  
  <h2 class="subtitle --center my-4">
    <mat-icon class="subtitle__icon">account_balance</mat-icon>
    {{ tc('yourBankAccount') }}
  </h2>

  <form [formGroup]="bankAccountFormGroup" class="check-seller-container">

    <mat-card class="check-seller-container__column">
      <mat-card-content>
        <app-loader *ngIf="isLoadingUserBankingInfo" diameter="40" class="my-5 mx-auto"></app-loader>

        <div *ngIf="!isLoadingUserBankingInfo" class="form">

          <ng-container *ngIf="!registeredBankingInfo?.hasBankAccount || isEdition; else registeredAccount">
            <!-- type -->
            <app-select-form-field
              class="form__field --full"
              [selections]="bankAccountTypeOptions"
              [form]="bankAccountFormGroup"
              [controlName]="'type'"
              [formModel]="userBanking"
              [validators]="['required']"
              [options]="{
                label: 'checkSeller.accountType'
              }"
            ></app-select-form-field>
            <!-- owner name -->
            <app-text-form-field
              class="mb-2 form__field --full"
              [controlName]="'ownerName'"
              [form]="bankAccountFormGroup"
              [formModel]="userBanking"
              [validators]="['required', 'pattern']"
              [options]="{
                label: 'checkSeller.accountHolder',
                pattern: nameRegex,
                trim: true
              }"
            ></app-text-form-field>
            <!-- IBAN -->
            <ng-container *ngIf="bankAccountFormGroup.value?.type === BankAccountType.IBAN">
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'IBAN'"
                [form]="bankAccountFormGroup"
                [formModel]="userBanking"
                [validators]="['required', 'minLength', 'pattern', 'uppercaseSpace4', 'maxLength', 'IBAN']"
                [options]="{
                  label: 'checkSeller.IBAN',
                  minLength: 15,
                  maxLength: 39,
                  pattern: '[A-Z]{2}[0-9]{2} ([a-zA-Z0-9]{4} ){2,7}[a-zA-Z0-9]{1,4}',
                  trim: true
                }"
              ></app-text-form-field>
            </ng-container>
            <!-- US account -->
            <ng-container *ngIf="bankAccountFormGroup.value?.type === BankAccountType.US">
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'USAccountNumber'"
                [form]="bankAccountFormGroup"
                [formModel]="userBanking"
                [validators]="['required', 'pattern']"
                [options]="{
                  label: 'checkSeller.accountNumber',
                  pattern: '[0-9]*'
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'USAbaRouting'"
                [form]="bankAccountFormGroup"
                [formModel]="userBanking"
                [validators]="['required', 'pattern', 'minLength', 'maxLength']"
                [options]="{
                  label: 'checkSeller.abaRouting',
                  pattern: '[0-9]{9}',
                  minLength: 9,
                  maxLength: 9
                }"
              ></app-text-form-field>
              {{ tc('additionalInformation') }}
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'address'"
                [form]="addressForm"
                [validators]="['required']"
                [options]="{
                  label: 'checkSeller.address',
                  trim: true
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'addressSupplement'"
                [form]="addressForm"
                [options]="{
                  label: 'checkSeller.addressSupplement',
                  trim: true
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'postCode'"
                [form]="addressForm"
                [validators]="['required']"
                [options]="{
                  label: 'checkSeller.postcode',
                  trim: true
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'city'"
                [form]="addressForm"
                [validators]="['required']"
                [options]="{
                  label: 'checkSeller.city',
                  trim: true
                }"
              ></app-text-form-field>
              <app-text-form-field
              class="mb-2 form__field --full"
              [controlName]="'region'"
              [form]="addressForm"
              [formModel]="userCopy"
              [validators]="['required']"
              [options]="{
                label: 'checkSeller.region',
                trim: true
              }"
            ></app-text-form-field>
            <app-autocomplete-form-field
              class="form__field --full"
              [controlName]="'countryOfResidence'"
              [selections]="countries"
              [form]="addressForm"
              [formModel]="userCopy"
              [validators]="['required']"
              [options]="{
                label: 'accountInfos.countryCode' | transloco
              }"
            ></app-autocomplete-form-field>
            </ng-container>
            <!-- CA account -->
            <ng-container *ngIf="bankAccountFormGroup.value?.type === BankAccountType.CA">
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'CABranchCode'"
                [form]="bankAccountFormGroup"
                [formModel]="userBanking"
                [validators]="['required', 'pattern', 'minLength', 'maxLength']"
                [options]="{
                  label: 'checkSeller.branchCode',
                  trim: true,
                  pattern: '[0-9]{5}',
                  minLength: 5,
                  maxLength: 5
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'CAInstitutionNumber'"
                [form]="bankAccountFormGroup"
                [formModel]="userBanking"
                [validators]="['required', 'pattern', 'minLength', 'maxLength']"
                [options]="{
                  label: 'checkSeller.instituationNumber',
                  pattern: '[0-9]{3}',
                  minLength: 3,
                  maxLength: 3
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'CAAccountNumber'"
                [form]="bankAccountFormGroup"
                [formModel]="userBanking"
                [validators]="['required', 'pattern', 'minLength', 'maxLength']"
                [options]="{
                  label: 'checkSeller.accountNumber',
                  pattern: '[0-9]{7,35}',
                  minLength: 7,
                  maxLength: 35
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'CABankName'"
                [form]="bankAccountFormGroup"
                [formModel]="userBanking"
                [validators]="['required', 'pattern']"
                [options]="{
                  label: 'checkSeller.bankName',
                  pattern: nameRegex,
                  trim: true
                }"
              ></app-text-form-field>
              {{ tc('additionalInformation') }}
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'address'"
                [form]="addressForm"
                [validators]="['required']"
                [options]="{
                  label: 'checkSeller.address',
                  trim: true
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'addressSupplement'"
                [form]="addressForm"
                [options]="{
                  label: 'checkSeller.addressSupplement',
                  trim: true
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'postCode'"
                [form]="addressForm"
                [validators]="['required']"
                [options]="{
                  label: 'checkSeller.postcode',
                  trim: true
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'city'"
                [form]="addressForm"
                [validators]="['required']"
                [options]="{
                  label: 'checkSeller.city',
                  trim: true
                }"
              ></app-text-form-field>
              <app-text-form-field
                class="mb-2 form__field --full"
                [controlName]="'region'"
                [form]="addressForm"
                [formModel]="userCopy"
                [validators]="['required']"
                [options]="{
                  label: 'checkSeller.region',
                  trim: true
                }"
              ></app-text-form-field>
              <app-autocomplete-form-field
                class="form__field --full"
                [controlName]="'countryOfResidence'"
                [selections]="countries"
                [form]="addressForm"
                [formModel]="userCopy"
                [validators]="['required']"
                [options]="{
                  label: 'accountInfos.countryCode' | transloco
                }"
              ></app-autocomplete-form-field>
              <mat-error *ngIf="addressForm.hasError('wrongCountryName')">{{ 'formErrors.wrongCountryName' | transloco }}</mat-error>
            </ng-container>

            <div *ngIf="registeredBankingInfo?.hasBankAccount" class="buttons-group mb-0 --row --centered">
              <button
                mat-button
                (click)="isEdition = false"
                type="button"
                class="button --outlined-primary --small"
              >
                {{ 'button.back' | transloco }}
              </button>
            </div>
          </ng-container>

          <ng-template #registeredAccount>
            <div class="d-flex align-items" *ngIf="registeredBankingInfo?.ownerName">
              <p class="mr-2">{{ tc('ownerNamePreview') }}</p>
              <p class="fw-600">{{registeredBankingInfo.ownerName}}</p>
            </div>
            <div class="d-flex align-items" *ngIf="registeredBankingInfo?.type">
              <p class="mr-2">{{ tc('typePreview') }}</p>
              <p class="fw-600">{{registeredBankingInfo.type}}</p>
            </div>
            <div class="d-flex align-items" *ngIf="registeredBankingInfo?.accountNumberPreview">
              <p class="mr-2">{{ tc('accountNumberPreview') }}</p>
              <p class="fw-600">{{registeredBankingInfo.accountNumberPreview}}</p>
            </div>

            <div class="buttons-group mb-0 --row --centered">
              <button
                mat-button
                (click)="confirmAccountDelete()"
                type="button"
                class="button --outlined-danger --small"
              >
                {{ tc('deleteThisAccount') }}
              </button>
              <button
                mat-button
                (click)="isEdition = true"
                type="button"
                class="button --outlined-primary --small"
              >
                {{ 'button.update' | transloco }}
              </button>
            </div>
          </ng-template>
        </div>
      </mat-card-content>
    </mat-card>

  </form>

  <div *ngIf="isSavingData" class="my-5">
    <app-loader diameter="40" class="mx-auto"></app-loader>
    <p *ngIf="userBanking?.mangoKYCLevel !== 'STRONG' && !userBanking?.mangoKYCId" class="mx-auto center fs-16">
      {{ tc('wait1Minute') }}
    </p>
  </div>

  <p *ngIf="!bankAccountFormValid" class="error fs-16 center mx-3 my-auto">{{ 'formErrors.bankAccountFormError' | transloco}}</p>
  <p *ngIf="!!error" class="error fs-16 center mx-3 my-auto">{{ tc(error) }}</p>
</ng-container>
