<ng-container *transloco="let te; read 'formErrors.controlDescription'">
  <ng-container *ngFor="
    let invalidControl of findInvalidControlsRecursive(form);
    let first = first;
    let last = last;
    let count = count;
  ">
    <ng-container *ngIf="count === 1; else manyControls">
      <span>{{ te('invalidControl', { controlName: te(invalidControl)}) }}</span>
    </ng-container>
    <ng-template #manyControls>
      <span *ngIf="first">{{ te('invalidControls') }}</span>
      <span>{{ te(invalidControl) }}</span>
      <span>{{ last ? '.' : ', '}}</span>
    </ng-template>
  </ng-container>
</ng-container>
