import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take, tap } from 'rxjs';
import { FaqSection } from '../../../models';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
})
export class HelpButtonComponent implements OnDestroy {
  public helpDialog: MatDialogRef<HelpDialogComponent, any>;
  public matDialogOpenState = MatDialogState.OPEN;
  private isBrowser = false;

  constructor(
    public dialog: MatDialog,
    private platform: Platform,
    @Inject(MAT_DIALOG_DATA) private data: { sections: FaqSection[] },
  ) {
    this.isBrowser = this.platform.isBrowser;
  }

  triggerModal() {
    if (this.helpDialog) {
      this.helpDialog?.close();
      return;
    }
    this.helpDialog = this.dialog.open(HelpDialogComponent, {
      closeOnNavigation: false,
      maxWidth: '100vw',
      hasBackdrop: false,
      position: {
        bottom: '0px',
        right: '0px',
      },
      panelClass: 'as-help-dialog',
      autoFocus: false,
      scrollStrategy: this.isBrowser && window.matchMedia('(min-width: 992px)').matches ?
        new NoopScrollStrategy() // allows to scroll with opened dialog on large screen
        : undefined,
    });
    this.helpDialog.afterClosed().pipe(
      take(1),
      tap( () => {
        this.helpDialog = null;
      }),
    ).subscribe();
  }

  ngOnDestroy() {
    this.helpDialog?.close();
  }

}
