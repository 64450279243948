<mat-card *ngIf="feedback" class="feedback-card">
  <p class="feedback-card__quote">
    {{feedback.quote}}
  </p>
  <div class="feedback-card__person">
    <div class="feedback-card__person__avatar">
      <ng-container *ngIf="feedback.gender === 'MAN'">
        <img src="assets/img/illustration/user-man.svg" alt="user-man">
      </ng-container>
      <ng-container *ngIf="feedback.gender === 'WOMAN'">
        <img src="assets/img/illustration/user-woman.svg" alt="user-woman">
      </ng-container>
    </div>
    <div class="feedback-card__person__info">
      <p class="feedback-card__person__info__name">
        {{feedback.name}}
      </p>
      <p class="feedback-card__person__info__event">
        {{feedback.event}}
      </p>
    </div>
  </div>
</mat-card>
