<ng-container *transloco="let t;">

  <mat-icon
    *ngIf="saved"
    class="material-icons"
    [matTooltip]="t('common.saved')"
  >
    cloud_done
  </mat-icon>

  <mat-icon
    *ngIf="pendingSave"
    class="material-icons"
    [matTooltip]="t('common.pendingSave')"
  >
    pending
  </mat-icon>

  <mat-icon
    *ngIf="saving"
    class="material-icons"
    [matTooltip]="t('common.saving')"
  >
    cloud_sync
  </mat-icon>

</ng-container>
