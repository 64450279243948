<mat-form-field
  *transloco="let t"
  class="form__field"
  [subscriptSizing]="'dynamic'"
>
  <mat-label>{{ t(options?.label, options?.labelParam) }}</mat-label>
  <mat-select [formControl]="control" [required]="validators.includes('required')">
    <mat-select-trigger>
      <div>
        <mat-icon
          *ngIf="getSelection(control.value)?.icon"
          class="select-form-field__trigger__icon"
        >{{ getSelection(control.value).icon }}</mat-icon>
        {{ t(getSelection(control?.value)?.viewValue) }}
      </div>
    </mat-select-trigger>
    <!-- display clear option -->
    <mat-option [value]="null" *ngIf="options?.displayClearButton && !!control.value">
      <mat-icon>clear</mat-icon>
      {{ t('dashboard.exploreTable.GenericDelete') }}
    </mat-option>
    <ng-container *ngIf="$any(selections?.[0])?.values">
      <mat-optgroup *ngFor="let group of $any(selections)" [label]="t(group.name)">
        <mat-option *ngFor="let option of group.values" [value]="option.value" [disabled]="option.disabled">
          <mat-icon *ngIf="option.icon">{{option.icon}}</mat-icon>
          {{ t(option.viewValue) }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
    <ng-container *ngIf="!$any(selections?.[0])?.values">
      <mat-option *ngFor="let option of $any(selections)" [value]="option.value" [disabled]="option.disabled">
        <mat-icon *ngIf="option.icon">{{option.icon}}</mat-icon>
        {{ t(option.viewValue) }}
      </mat-option>
    </ng-container>
  </mat-select>
  <mat-icon matSuffix *ngIf="options?.iconName"> {{ options.iconName }}</mat-icon>
  <mat-hint>{{ t(options?.hint) }}</mat-hint>
  <mat-error *ngIf="control.errors">{{ 'formErrors.' + Objectkeys(control.errors)?.[0] | transloco }}</mat-error>
</mat-form-field>
