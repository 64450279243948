import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { EventStore } from '../../../stores';
import { Event, EventStatus, Language } from '../../../models';
import { AppService, ColorService, NotificationService } from '../../../services';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-sale-purchase',
  templateUrl: './sale-purchase.component.html',
  styleUrls: ['./sale-purchase.component.scss'],
})
export class SalePurchaseComponent implements OnInit, OnDestroy {
  @Input() widget: boolean = false;
  public isLoading = false;
  public event: Event;
  public EventStatus = EventStatus;
  public currentLang: Language;

  private eventSub: Subscription;
  private langSub: Subscription;

  constructor(
    private eventStore: EventStore,
    private colorService: ColorService,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    public appService: AppService,
  ) {
    this.langSub = this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.currentLang = lang;
    });
  }

  ngOnInit() {
    this.isLoading= true;
    this.eventSub = this.eventStore.currentEventId$.pipe(
      tap( () => {
        this.event = this.eventStore.getCurrentEvent();
        if (this.event.color) {
          this.colorService.setEventColor(this.event);
        }
        if (!this.event.organizerName) {
          this.event.organizerName = this.event.name;
        }

        if (!this.appService.settings.value?.isWidget) {
          this.notificationService.activity(this.event.totalTicketSold, this.event.id);
        }
        this.isLoading = false;
      }),
    ).subscribe({
      next: () => {},
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log('Error on getEvent in sale / purchase : ', err);
        this.isLoading = false;
      },
    });
  }

  ngOnDestroy() {
    this.eventSub?.unsubscribe();
    this.langSub?.unsubscribe();
  }

}
