import { Component, Input } from '@angular/core';
import { Event } from '../../../models';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent {
  @Input() event: Pick<Event, 'name' | 'url' | 'location' | 'imgUrl' | 'dateStart' | 'dateEnd' | 'PostalAddress'>;
  @Input() oldWidget?: boolean;

  constructor() { }

}
