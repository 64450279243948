<div class="small-container" *transloco="let tav; read 'accountValidation'">

  <app-loader *ngIf="isLoadingVerifying" diameter="40" class="my-5 mx-auto"></app-loader>

  <!-- if user is verified -->
  <div *ngIf="!isLoadingVerifying && isTokenValid">

    <!-- If token is valid, then the user can choose his new password -->
    <div *ngIf="!isPasswordUpdated" class="login center">

      <h1 class="subtitle --center mb-3">{{ tav('passwordReset') }}</h1>

      <mat-card class="mt-4 mb-5">
        <mat-card-content>
          <form class="form --centered" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitResetPassword()">

            <mat-form-field class="form__field mb-2">
              <mat-label>{{tav('password')}}</mat-label>
              <input formControlName="password" matInput (input)="checkPasswordMatch()" [type]="hidePassword ? 'password' : 'text'" required>
              <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="getError('password')">{{ 'formErrors.' + getError('password') | transloco }}</mat-error>
            </mat-form-field>

            <mat-form-field class="form__field mb-2">
              <mat-label>{{tav('passwordConfirmation')}}</mat-label>
              <input formControlName="passwordConfirm" matInput (input)="checkPasswordMatch()" [type]="hideConfirmPassword ? 'password' : 'text'" required>
              <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
                <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="getError('passwordConfirm')">{{ 'formErrors.' + getError('passwordConfirm') | transloco }}</mat-error>
            </mat-form-field>

            <app-password-strength class="form__field full-width text-left mb-3" [form]="resetPasswordForm"></app-password-strength>

            <app-loader *ngIf="isLoadingPasswordUpdate" diameter="40" class="m-auto"></app-loader>

            <p class="full-width error center" *ngIf="!!error">{{ 'formErrors.' + error | transloco }}</p>

            <div class="buttons-group --row --centered">  
              <button mat-button class="button --small --full-width --primary" type="submit">{{ tav('resetMyPassword') }}</button>
            </div>

          </form>
        </mat-card-content>
      </mat-card>

    </div>

    <!-- Once password has well been updated -->
    <div *ngIf="isPasswordUpdated">

      <p class="text">
        {{ tav('congratulationsPasswordUpdated') }}
      </p>

      <p class="text" *ngIf="specificProcess === processType.Selling">
        {{ tav('saleNotFinished') }}<br>
        {{ tav('goBackToPreviousTab') }}
      </p>

      <p class="text" *ngIf="specificProcess === processType.WaitList">
        {{ tav('waitlistRegistrationNotComplete') }}<br>
        {{ tav('goBackToPreviousTab') }}
      </p>

      <ng-container *ngIf="!specificProcess">
        <p class="text">
          {{ tav('youCanNowLogIn') }}
        </p>
        <div class="buttons-group --row --centered">
          <a routerLink="/connexion">
            <button mat-button class="button --primary" type="button">
              {{ 'button.logIn' | transloco }}
            </button>
          </a>
        </div>
      </ng-container>
      
      <div class="illustration" *appLet="(appService.settings.asObservable() | async) as setting">
        <object
          type="image/svg+xml"
          [data]="'assets/img/illustration/happy.svg' + (setting.referer ? '?wref=' + setting.referer : '') | safe:'resourceUrl'"
          class="illustration__img"
        >
          <param name="color" value="url(#reelax-gradient)">  
        </object>
      </div>
    </div>
  
  </div>

  <!-- if token is not valid anymore -->
  <ng-container *ngIf="!isLoadingVerifying && !isTokenValid">
    <h1 class="title --page mb-3">{{ tav('passwordReset') }}</h1>

    <mat-card class="mt-4 mb-5">
      <mat-card-content>
        <div *ngIf="!isRegenerateDisabled">
          <p class="text">
            {{ tav('validationLinkExpired') }}
            <br><br>
            {{ tav('clickToGenerateNewLink') }}
          </p>
          
          <div class="buttons-group --row --centered">
            <button mat-button class="button --primary" (click)="onRegenerateClick()" type="button">{{ tav('generateANewLink') }}</button>
          </div>
        </div>

        <p *ngIf="isRegenerateDisabled" class="text">
          {{ tav('newLinkSent') }}
          <br><br>
          {{ tav('checkYourEmails') }}
        </p>
      </mat-card-content>
    </mat-card>
  </ng-container>

</div>