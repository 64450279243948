<mat-form-field
  class="form__field"
  [subscriptSizing]="'dynamic'"
>
  <mat-label *ngIf="options.label?.length > 0">{{options.label}}</mat-label>
  <button 
    *ngIf="options.increment"
    mat-icon-button 
    matPrefix
    aria-label="Remove" 
    (click)="setValue(-options.increment); $event.stopPropagation(); control.markAsTouched()" 
    [disabled]="disabled || (hasMin() && control.value <= options.min)"
    type="button"
  >
    <mat-icon>remove</mat-icon>
  </button>
  <input
    class="input"
    [class.center]="options.increment"
    type="number"
    matInput
    [formControl]="control"
    [min]="options?.min"
    [max]="options?.max"
    [required]="validators.includes('required')"
    (onWheel)="$event?.preventDefault()"
  >
  <div matSuffix class="suffix">
    <button 
      *ngIf="options.increment"
      mat-icon-button 
      aria-label="Add" 
      (click)="setValue(options.increment); $event.stopPropagation(); control.markAsTouched()" 
      [disabled]="disabled || (hasMax() && control.value >= options.max)"
      type="button"
    >
      <mat-icon>add</mat-icon>
    </button>
    <mat-icon *ngIf="options?.iconName"> {{ options.iconName }}</mat-icon>
  </div>
  <mat-hint>{{options?.hint}}</mat-hint>
  <mat-error *ngIf="control.errors">{{ 'formErrors.' + Objectkeys(control.errors)?.[0] | transloco }}</mat-error>
</mat-form-field>
