import { Component, Input, OnInit } from '@angular/core';
import { Language, LanguageMap } from '../../../models';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-category-description',
  templateUrl: './category-description.component.html',
  styleUrls: ['./category-description.component.scss'],
})
export class CategoryDescriptionComponent implements OnInit {
  @Input() public description: LanguageMap;
  @Input() public displayed = false;
  public Language = Language;
  public hasOneTranslation = false;

  constructor(
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.hasOneTranslation = this.description &&
      this.Language &&
      Object.values(this.Language)?.some((l) => this.description[l]?.trim()?.length > 0);
  }

  getTranslatedDescription() {
    if (this.description[this.translocoService.translate('language.localeCountry')]?.trim()?.length > 0) {
      return this.description[this.translocoService.translate('language.localeCountry')];
    } else if (this.description[Language.frFR]?.trim()?.length > 0) {
      return this.description[Language.frFR];
    } else if (this.description[Language.enUS]?.trim()?.length > 0) {
      return this.description[Language.enUS];
    } else if (this.description[Language.esES]?.trim()?.length > 0) {
      return this.description[Language.esES];
    }
    return undefined;
  }
}
