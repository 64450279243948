import { Component, Input } from '@angular/core';

enum gender {
  MAN = 'MAN',
  WOMAN = 'WOMAN'
}

@Component({
  selector: 'app-feedback-card',
  templateUrl: './feedback-card.component.html',
  styleUrls: ['./feedback-card.component.scss'],
})
export class FeedbackCardComponent {
  @Input() feedback: {event: string, gender: gender, name: string, quote: string};

  constructor() {}

}
