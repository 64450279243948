import { Router } from '@angular/router';
import { captureException } from '@sentry/angular-ivy';
import { catchError, Observable, of } from 'rxjs';
import { AuthService } from '../../services';

export class RxjsUtils {

  static backToLoginIf401(router: Router, authService: AuthService) {
    return function<T>(source: Observable<T>) {
      return source.pipe(
        catchError( (err) => {
          if(err.status === 401) {
            console.log('backToLoginIf401 ', authService?.webToken ? JSON.stringify(authService.webToken) : 'notoken', new Date().toISOString())
            captureException(err); // added to debug myAccount redirection after login
            router.navigate(['login']);
            authService.forceReload();
          }
          return of();
        }),
      );
    };
  }
}
