<ng-container *transloco="let tts; read 'ticketSharing'">
  <ng-container *ngIf="tickets?.[0]?.id && (event?.id || event?.url)">

    <app-social-media-preview
      [event]="event"
      [ticket]="tickets[0]"
      type="buy"
      (click)="copyAnnounceURL(tickets?.[0]?.id)" [matTooltip]="tts('copyMySaleAdLink')"
      [appTrack]="{category: 'sale', action: 'share-ticket', name: event.url, value: tickets?.[0]?.id, customDimension: '{ticketId: ' + tickets?.[0]?.id +'}'}"
    >
    </app-social-media-preview>

    <ng-container *ngFor="let ticket of tickets; let i=index; let last = last">
      <p *ngIf="tickets?.length > 1" class="confirmation-page__text text-left fw-700">{{ tts('saleAdLink', {number: (tickets?.length > 1 ? (i+1) : '')}) }}</p>
      <div class="d-flex --vertical-centered">
        <span class="confirmation-page__text text-left selectable">{{ announceUrls?.[ticket?.id] }}</span>
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="copyAnnounceURL(ticket?.id)"
          [appTrack]="{category: 'sale', action: 'share-ticket', name: event.url, value: ticket?.id, customDimension: '{ticketId: ' + ticket?.id +'}'}"
          [matTooltip]="tts('copyMySaleAdLink')">
          <mat-icon>content_copy</mat-icon>
        </button>
      </div>
      <br *ngIf="!last">
    </ng-container>
  </ng-container>
</ng-container>
