import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';

@Component({
  selector: 'app-checkbox-form-field',
  templateUrl: './checkbox-form-field.component.html',
})
export class CheckboxFormFieldComponent extends FormField<boolean> implements OnInit, OnDestroy {

  constructor() {
    super();
  }

}
