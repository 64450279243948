import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormField } from '../form';

@Component({
  selector: 'app-textarea-form-field',
  templateUrl: './textarea-form-field.component.html',
  styleUrls: ['./textarea-form-field.component.scss'],
})
export class TextareaFormFieldComponent extends FormField<string> implements OnInit, OnDestroy {

  constructor() {
    super();
  }

}
